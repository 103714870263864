
import ContainerFarm from "../../../Component/Dashboard/Farm/Farm"


const Farm = () => {

    return (
        <div className='page-body'>
          <ContainerFarm />
        </div>
      )
}

export default Farm