import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LayoutRoutes from "./LayoutRoutes";
import Login from "../Component/Authentication/Login";
import { authRoutes } from "./AuthRoutes";
import { AuthProvider } from "../Component/Authentication/AuthContext";
import { useAppSelector } from "../ReduxToolkit/Hooks";

const RouterData = () => {
  // const login = localStorage.getItem("login");

  const userData = useAppSelector(state => state.user.tokens)
  console.log("TOKENS IN REDUX", userData)
  return (
    <BrowserRouter basename={"/"}>
      <AuthProvider>
        <Routes>
          {userData.access ? (
            <>
              <Route
                path={`${process.env.PUBLIC_URL}` || '/'}
                element={
                  <Navigate to={`${process.env.PUBLIC_URL}/dashboard/default`} />
                }
              />
            </>
          ) : (
            ""
          )}
          <Route path={"/"} element={<PrivateRoute />}>
            <Route path={`/*`} element={<LayoutRoutes />} />
          </Route>
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
          <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default RouterData;
