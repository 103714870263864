import { Col } from "reactstrap";
import { H6, LI, UL } from "../../../../../AbstractElements";
import { searchTabsData } from "../../../../../Data/Application/SearchResult/SearchResult";
import { Link } from "react-router-dom";
import { Href } from "../../../../../utils/Constant";

const VideoTab2 = () => {
  return (
    <Col xxl="6">
      <H6 className="mb-2">{"About 6,000 results (0.60 seconds)"}</H6>
    </Col>
  )
}

export default VideoTab2