
import UpdateFarms from "../../../Component/Dashboard/Farm/UpdateFarm/UpdateFarms"

 const UpdateFarm = () => {
    return (
        <>
            <UpdateFarms />
        </>
    )
}

export default UpdateFarm