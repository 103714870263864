import { Card, CardBody, Row, TabContent, TabPane } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks'
import ProjectCommon from './Common/ProjectCommon'
import { useEffect } from 'react'
import { getAllProjects } from '../../../../ReduxToolkit/Reducers/ProjectSlice'

const ProjectListTabContent = () => {
    const {activeTab,createdFormData, projectList, alreadyRequested} = useAppSelector((state)=>state.project)
    const dispatch = useAppDispatch()
    
    useEffect(() => {
      if(projectList.length === 0 && alreadyRequested === false) {
        dispatch(getAllProjects())
      }
    }, [])
    return (
      <Card>
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                {projectList.length > 0 && projectList.map((item, i) => (<ProjectCommon project={item} key={i} />))}
              </Row>
            </TabPane>
            
          </TabContent>
        </CardBody>
      </Card>
    )
}

export default ProjectListTabContent