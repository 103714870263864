
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { backURL } from "../../utils/Constant";
import axios from "axios"
import { CustomProjectType } from "./ProjectSlice";
import { refreshToken } from "./UserSlice";
const swal = require('sweetalert2')


type CreateActivityNameType = {
    name: string
    description: string
}

type userProfileType = {
    name: string
    description: string
    id: number
    creatorId: number
}

type deleteActivityNameType = {
    id: number
}

type StockingActUpdateData = {
    farmId: number
    quantity: number
}

type StockSourceFetchData = {
    farmId: number
}

type StockSource = {
    name: string
    description: string
    PurchaseId: number
    farmId: number
    vendorId: number
    id: number
}

type StaffFetchData = {
    farmId: number
}

export type StaffSource = {
    id: number
    firstName: string
    lastName: string
    position: string
    managerId: number
    dateOfBirth: string
    userId: any
    farmId: number
    phoneMain: string
    phoneSecondary: string
    email: string
    homeAddress: string
    dataCreated: string
    employmentDate: string
}

type StockingFetchData = {
    farmId: number
    toPondId: number
    fromPondId: number
    fishStage: string
    fishId: number
    addedQuantity: number
    addedWeight: number
    comments: string

}

export type FarmToDoType = {   

    createDate:string 
    dueDate: string
    id: number
    pomdName: string
    pondId: number
    projectId: number
    requestorId: number
    status: number
    taskDetails: string
    taskId: number
    taskName: string
    urgency: number     
}
export type TodoActivityType = {
    name: string
    description: string
    creatorId: number
    id: number
}
type FarmSliceType = {
    data: {
        email: string
        username: string
        id: number
    },
    userProfile: userProfileType
    farmList: {
        alreadyRequested: boolean,
        list: CustomProjectType[]
    },
    stockSourceList: StockSource[]
    staffList: StaffSource[],
    toDoList: FarmToDoType[],
    activities: TodoActivityType[]
}

const initialState: FarmSliceType = {

    data: {
        email: "",
        username: "",
        id: 0
    },
    userProfile: {
        name: "",
        description: "",
        id: 0,
        creatorId: 0
    },
    farmList: {
        alreadyRequested: false,
        list: []
    },
    stockSourceList: [],
    staffList: [],
    toDoList: [],
    activities: []
}


export const getActivityName = createAsyncThunk(
    'project/getActivityName',
    async (_, thunkAPI) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${backURL}/projects/activityNames/`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
                }
            })
            if (response.status === 401) {
                //REFRESH TOKEN
                //await refreshToken()
            }
            console.log(response.data);

            return response.data
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)

export const createActivityName = createAsyncThunk(
    'project/createActivityName',
    async (data: CreateActivityNameType, thunkAPI) => {
        try {
            const sendData = {
                name: data.name.toUpperCase(),
                description: data.description,
            }
            const response = await axios({
                method: 'PUT',
                url: `${backURL}/projects/activityNames/`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
                },
                data: sendData
            })
            console.log(response.data);
            return response.data
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
);

export const deleteActivityName = createAsyncThunk(
    'project/deleteActivityName',
    async (data: deleteActivityNameType, thunkAPI) => {
        try {
            const sendData = {
                id: data.id,
            }
            const response = await axios({
                method: 'DELETE',
                url: `${backURL}/projects/activityNames/`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
                },
                data: sendData
            })
            console.log(response.data);
            return response.data
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
);

export const updateStockingActivity = createAsyncThunk(
    'farm/updateStockingActivity',
    async (data: StockingActUpdateData, thunkAPI) => {


    }
)


export const getAllFarmsForForm = createAsyncThunk(
    'farm/getAllFarmsForForm',
    async (_, thunkAPI) => {
        try {
            // const farmsMockUp:CustomProjectType[] = [
            //     {
            //         creatorId: 1,
            //         fullsAddress: "adressFarm1",
            //         id: 1,
            //         name: "Farm1",
            //         summary: "SumFarm1",
            //         zipCode: 123
            //     },
            //     {
            //         creatorId: 2,
            //         fullsAddress: "adressFarm2",
            //         id: 2,
            //         name: "Farm2",
            //         summary: "SumFarm2",
            //         zipCode: 456
            //     },
            //     {
            //         creatorId: 3,
            //         fullsAddress: "adressFarm3",
            //         id: 3,
            //         name: "Farm3",
            //         summary: "SumFarm3",
            //         zipCode: 789
            //     },
            // ]
            // return farmsMockUp
            const response = await axios({
                method: 'GET',
                url: `${backURL}/projects/myProjects/`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
                }
            })
            if (response.status === 401) {
                //REFRESH TOKEN
                await refreshToken()
            }
            console.log(response.data);
            return response.data.data
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)
export const getAllStockSource = createAsyncThunk(
    'farm/getAllStockSource',
    async (data: StockSourceFetchData, thunkAPI) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${backURL}/projects/fishSource/`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
                },
                data: {
                    farmId: data.farmId
                }
            })
            if (response.status === 401) {
                //REFRESH TOKEN
                await refreshToken()
            }
            console.log(response.data.data);
            return response.data.data
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)
export const getAllStaff = createAsyncThunk(
    'farm/getAllStaff',
    async (data: StaffFetchData, thunkAPI) => {
        // const staffFarm1Mockup:StaffSource[] = [
        //     {
        //         id: 1,
        //         firstName: "staff1Farm1",
        //         lastName: "staff1_1",
        //         dataCreated: "created",
        //         dateOfBirth: "01/01/1100",
        //         email: "email1",
        //         employmentDate: "01/01/01",
        //         farmId: 1,
        //         homeAddress: "address1",
        //         managerId: 1,
        //         phoneMain: "144444",
        //         phoneSecondary: "144777",
        //         position: "great",
        //         userId: 1,
        //     },
        //     {
        //         id: 2,
        //         firstName: "staff2Farm1",
        //         lastName: "staff2_1",
        //         dataCreated: "created",
        //         dateOfBirth: "01/01/1100",
        //         email: "email1",
        //         employmentDate: "01/01/01",
        //         farmId: 1,
        //         homeAddress: "address1",
        //         managerId: 1,
        //         phoneMain: "144444",
        //         phoneSecondary: "144777",
        //         position: "great",
        //         userId: 1,
        //     }
        // ]
        // const staffFarm2Mockup:StaffSource[] = [
        //     {
        //         id: 1,
        //         firstName: "staff1Farm2",
        //         lastName: "staff1_1",
        //         dataCreated: "created",
        //         dateOfBirth: "01/01/1100",
        //         email: "email1",
        //         employmentDate: "01/01/01",
        //         farmId: 1,
        //         homeAddress: "address1",
        //         managerId: 1,
        //         phoneMain: "144444",
        //         phoneSecondary: "144777",
        //         position: "great",
        //         userId: 1,
        //     },
        //     {
        //         id: 2,
        //         firstName: "staff2Farm2",
        //         lastName: "staff2_1",
        //         dataCreated: "created",
        //         dateOfBirth: "01/01/1100",
        //         email: "email1",
        //         employmentDate: "01/01/01",
        //         farmId: 1,
        //         homeAddress: "address1",
        //         managerId: 1,
        //         phoneMain: "144444",
        //         phoneSecondary: "144777",
        //         position: "great",
        //         userId: 1,
        //     }
        // ]
        // return data.farmId === 1 ? staffFarm1Mockup : staffFarm2Mockup
        try {
            const response = await axios({
                method: 'POST',
                url: `${backURL}/projects/Staff/`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
                },
                data: {
                    farmId: data.farmId
                }
            })
            if (response.status === 401) {
                //REFRESH TOKEN
                await refreshToken()
            }
            console.log(response.data.data);
            return response.data.data
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)

export const cretateStocking = createAsyncThunk(
    
    'farm/cretateStocking',
    async (data: StockingFetchData, thunkAPI) => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${backURL}/projects/Stockings/`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
                },
                data: data, 
            })
            if (response.status === 401) {
                //REFRESH TOKEN
                await refreshToken()
            }
            console.log(response.data.data);
            return response.data.data

        } catch (err: any) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)

type GetFarmToDoT = {
    farmId: number
}
export const getFarmToDo = createAsyncThunk(
    'farm/getFarmToDo',
    async (data:GetFarmToDoT, thunkAPI) => {
      try {
        // const ToDoMockUp = [
        //     {
        //         id: 11,
        //         pondId: 1,
        //         taskId: 1,
        //         dueDate: "2024-09-23T00:00:00Z",
        //         urgency: 2,
        //         status: 3,
        //         requestorId: 2,
        //         taskDetails: "Top water",
        //         farmdId: 1
        //     },
        //     {
        //         id: 11,
        //         pondId: 4,
        //         taskId: 2,
        //         dueDate: "2024-09-23T00:00:00Z",
        //         urgency: 2,
        //         status: 3,
        //         requestorId: 2,
        //         taskDetails: "DetailTask2",
        //         farmdId: 2
        //     },
        //     {
        //         id: 11,
        //         pondId: 1,
        //         taskId: 3,
        //         dueDate: "2024-09-23T00:00:00Z",
        //         urgency: 2,
        //         status: 3,
        //         requestorId: 2,
        //         taskDetails: "DetailTask3",
        //         farmdId: 1
        //     },
        //     {
        //         id: 11,
        //         pondId: 2,
        //         taskId: 1,
        //         dueDate: "2024-09-23T00:00:00Z",
        //         urgency: 2,
        //         status: 3,
        //         requestorId: 2,
        //         taskDetails: "DetailTask4",
        //         farmdId: 2
        //     },
        //     {
        //         id: 11,
        //         pondId: 3,
        //         taskId: 2, 
        //         dueDate: "2024-09-23T00:00:00Z",
        //         urgency: 2,
        //         status: 3,
        //         requestorId: 2,
        //         taskDetails: "DetailTask5",
        //         farmdId: 1
        //     },
        // ]
        // return ToDoMockUp
        console.log("FarmId send to ToDo: ", data)
        const response = await axios({
          method: 'POST',
          url: `${backURL}/projects/Ponds/todo/`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
          },
          data: data
        })
        if (response.status === 401) {
          //REFRESH TOKEN
          await refreshToken()
        }
        console.log(`Response from To Do with farmId: ${data.farmId} `, response.data )
        return response.data.data
      } catch (err: any) {
        return thunkAPI.rejectWithValue(err.response)
      }
    }
  
  );

  export const getActivities = createAsyncThunk(
    'farm/activities', 
    async (_, thunkAPI) => {
        try {

            // const mockUpActivities = [
            //     {
            //         name: "Stocking",
            //         description: "StockDesc",
            //         createorId: 1,
            //         id: 1
            //     },
            //     {
            //         name: "Feeding",
            //         description: "FeedingDesc",
            //         createorId: 1,
            //         id: 2
            //     },
            //     {
            //         name: "Water Change",
            //         description: "Water Change desc",
            //         createorId: 1,
            //         id: 3
            //     }
            // ]
            // return mockUpActivities
            const response = await axios({
                method: "POST",
                url: `${backURL}/projects/activityNames/`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
                },    
            })
            if (response.status === 401) {
                //REFRESH TOKEN
                await refreshToken()
              }
              return response.data.data
        } catch(err:any) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
  )

const farmSlice = createSlice({
    name: 'farmSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(createActivityName.pending, (state, _) => {
            console.log("Processing... !!!")
            swal.fire({
                title: "Processing... Please Wait!!!",
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-center',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        })

        builder.addCase(getActivityName.pending, (state, _) => {
            console.log("Getting user Activities Name")
        })
        builder.addCase(getActivityName.rejected, (state, action: any) => {
            console.log(action.payload)
            swal.fire({
                title: "An error ocurred",
                icon: "error",
                toast: true,
                timer: 2000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
            state.userProfile = action.payload
        })
        builder.addCase(getActivityName.fulfilled, (state, action: any) => {


            const activityNameProfileReceived = {
                name: action.payload.data.name,
                description: action.payload.data.description,
                creatorId: action.payload.data.creatorId,
                id: action.payload.data.id
            }
            state.userProfile = action.payload
        })

        //FarmsForForm
        builder.addCase(getAllFarmsForForm.pending, (state, _) => {
            console.log("Getting Farms for Form")
        })
        builder.addCase(getAllFarmsForForm.rejected, (state, action: any) => {
            console.log("Error getAllFarmsForForm", action.payload)
            state.farmList.alreadyRequested = true
            swal.fire({
                title: "An error ocurred",
                icon: "error",
                toast: true,
                timer: 1000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        })
        builder.addCase(getAllFarmsForForm.fulfilled, (state, action: any) => {
            console.log("Farms for Forms: ", action.payload)
            state.farmList.list = action.payload
            state.farmList.alreadyRequested = true
        })

        //Stock Source
        builder.addCase(getAllStockSource.pending, (state, _) => {
            console.log("Getting stock source")
        })
        builder.addCase(getAllStockSource.rejected, (state, action: any) => {
            swal.fire({
                title: "An error ocurred",
                icon: "error",
                toast: true,
                timer: 1000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        })
        builder.addCase(getAllStockSource.fulfilled, (state, action: any) => {
            console.log("Stock Source Response: ", action.payload)
            if (!action.payload || action.payload.length === 0) {
                swal.fire({
                    title: "no stock exist, create one before",
                    icon: "error",
                    toast: true,
                    timer: 1000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                })
            }
            else {
                state.stockSourceList = action.payload
            }
        })

        //Staff
        builder.addCase(getAllStaff.pending, (state, _) => {
            console.log("Getting Company Workers....")
        })
        builder.addCase(getAllStaff.rejected, (state, action: any) => {
            console.log("Failed to Get Company Workers....")
        })
        builder.addCase(getAllStaff.fulfilled, (state, action: any) => {
            console.log("Staff Data Response: ", action.payload)
            if (!action.payload || action.payload.length === 0) {
                console.log("Company Has no Worker(s)....")
            }
            else {
                state.staffList = action.payload
            }
        })
        //GET TO DO LIST
        builder.addCase(getFarmToDo.pending, (state, _) => {
            console.log("Getting Ponds ToDo List");
            swal.fire({
            title: "Loading ToDO List",
            icon: "loading",
            toast: true,
            timer: 6000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
            })
        })
        builder.addCase(getFarmToDo.rejected, (state, action: any) => {
            console.log(action.payload)
            swal.fire({
            title: "An error ocurred",
            icon: "error",
            toast: true,
            timer: 6000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
            })
        })
        builder.addCase(getFarmToDo.fulfilled, (state, action:any) => {
            console.log("FULLFILED GET TODO LIST: ", action.payload)
            if(action.payload) state.toDoList= action.payload
        })
        
        //Get activities
        builder.addCase(getActivities.pending, (state, _) => {
            console.log("Getting activities")
        })
        builder.addCase(getActivities.rejected, (state, action:any) => {
            console.log("error getting activities")
            swal.fire({
                title: "An error ocurred",
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
                })
        })
        builder.addCase(getActivities.fulfilled, (state, action:any) => {
            console.log("Fullfiled activities: ", action.payload)
            state.activities = action.payload
        })
        


    }

});




export default farmSlice.reducer;