import { Business, CreatedByMe, Favourites, Holidays, Important, MyBookmark, Newsletter, Notification, Orgenization, SharedWithMe } from "../../../utils/Constant";

 export const sideBarData = [
    { value: "1", tittle: CreatedByMe },
    { value: "2", tittle: Favourites },
    { value: "3", tittle: SharedWithMe },
    { value: "4", tittle: MyBookmark },
  ];
  export const sideBarData2 = [
    { value: "5", tittle: Notification },
    { value: "6", tittle: Newsletter },
    { value: "7", tittle: Business },
    { value: "8", tittle: Holidays },
    { value: "9", tittle: Important },
    { value: "10", tittle: Orgenization },
  ];
  
  export const bookMarkData = [
  ];
  