
export const stockingNavData = [
    {
      activeTab: 1,
      iconName: "fa-user",
      tittle: "Stocking Details",
    },
    {
      activeTab: 2,
      iconName: "fa-chain-broken",
      tittle: "Associated Task",
    },
    {
      activeTab: 3,
      iconName: "fa-group",
      tittle: "Inquiries",
    },
    {
      iconName: "fa-group",
      tittle: "Completed ",
    },
  ];