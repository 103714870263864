
import CommonModal from "../../../../Ui-Kits/Modal/Common/CommonModal";
import { useState } from 'react'

import { Btn } from "../../../../../AbstractElements";
import SvgIcon from "../../../../../CommonElements/SVG/SvgIcon";

import StockingForm from "../Forms/StockingForm";
import CustomHorizontalWizard from "../../../../Forms/FormsLayout/FormWizardTwo/CustomHorizontalWizard/CustomHorizontalWizard";

const StockingModal = () => {

    const [StockingModalScreen, setStockingModalScreen] = useState(false);
    const setStockingModalScreenToggle = () => setStockingModalScreen(!StockingModalScreen);

    const tilte = 'Stocking Fish in Ponds';
    const heading = 'Update Pond Stock';

    return (
        <>
            <Btn color="warning" onClick={setStockingModalScreenToggle}> <SvgIcon className='feather' iconId='user-plus' /> {' Stocking'}</Btn>
            <CommonModal size="xl" isOpen={StockingModalScreen} toggle={setStockingModalScreenToggle} sizeTitle={tilte}>
                <StockingForm differentId heading={heading} horizontalWizardClass="vertical-options" firstXl={3} secondXl={9} />
                
            </CommonModal>
        </>
    )
}


export default StockingModal