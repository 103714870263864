import { teamMembersFourChart, teamMembersOneChart, teamMembersThreeChart, teamMembersTwoChart } from "../DashboardChartData"

export const totalProjectDetailData = [
    {
        color:"primary",
        title:"Completed Project",
        count:"321"
    },
    {
        color:"secondary",
        title:"Inprogress",
        count:"876"
    },
    {
        color:"tertiary",
        title:"Yet to Start",
        count:"645"
    }
] 

export const totalClientsData = [
   "7.jpg", "2.jpg", "1.jpg"
]

export const tasksProjectBodyData = [
    {
        svgIcon :"playbutton",
        title:"Begin from",
        time:"7.00 am",
        project:"This would contain all projects task list",
        details:[
            {
                icon:"paperclip",
                name:"www.dribbble.com",
            },
            {
                icon:"message-square",
                name:"6 comments"
            }
        ],
        percentage:"60%",
        value:"50",
        color:"primary"
    },
    {
        svgIcon :"pushbutton",
        title:"Begin from",
        time:"8.03 am",
        project:"Look for Project Ideas",
        details:[
            {
                icon:"paperclip",
                name:"www.dribbble.com",
            },
            {
                icon:"message-square",
                name:"5 comments"
            }
        ],
        percentage:"80%",
        value:"70",
        color:"secondary"
    },
    {
        svgIcon :"playbutton",
        title:"Begin from",
        time:"4.12 am",
        project:"How can i find inspiration?",
        details:[
            {
                icon:"paperclip",
                name:"www.dribbble.com",
            },
            {
                icon:"message-square",
                name:"4 comments"
            }
        ],
        percentage:"40%",
        value:"30",
        color:"tertiary"
    },
    {
        svgIcon :"playbutton",
        title:"Begin from",
        time:"7.30 am",
        project:"Can i execute my idea?",
        details:[
            {
                icon:"paperclip",
                name:"www.dribbble.com",
            },
            {
                icon:"message-square",
                name:"9 comments"
            }
        ],
        percentage:"50%",
        value:"50",
        color:"primary"
    }
]

export const todoListCardBodyData = [
    {
        color:"primary",
        id:"solid5",
        todo:"so powerfully and metaphysically",
        btnName:"Today"
    },
    {
        color:"primary",
        id:"solid4",
        todo:"Create a website page using a prototype.",
        btnName:"Today"
    },
    {
        color:"secondary",
        id:"solid3",
        todo:"Create a new landing page.",
        btnName:"3 Days"
    },
    {
        color:"secondary",
        id:"solid2",
        todo:"Complete the medical ui system idea.",
        btnName:"3 Days"
    },
    {
        color:"secondary",
        id:"solid1",
        todo:"Make a new landing page.",
        btnName:"3 Days"
    }
]

export const homeEstateData = [
    {
       color:"primary",
       icon:"dash-home",
       title:"Home Estate",
       course:"Web Design",
       percentage:"50%",
       value:"55",
       imageOne:"avatar/6.jpg",
       imageTwo:"user/8.jpg",
       number:4
    },
    {
        color:"secondary",
        icon:"dash-development",
        title:"Development",
        course:"Coding",
        percentage:"40%",
        value:"55",
        imageOne:"user/6.jpg",
        imageTwo:"user/9.jpg",
        number:2
     },
     {
        color:"tertiary",
        icon:"dash-vector",
        title:"NFT Design",
        course:"Design",
        percentage:"90%",
        value:"55",
        imageOne:"avatar/11.jpg",
        imageTwo:"user/3.jpg",
        number:3
     }
]

export const teamMembersBodyData = [
    {
        image:"10.jpg",
        clientName:"Kingsley Ugochukwu",
        email:"rick35@gmail.com",
        designation:"Website",
        task:"256",
        chart:teamMembersOneChart
    },
    {
        image:"11.jpg",
        clientName:"Susan Connor",
        email:"susan6@gmail.com",
        designation:"Mobile App",
        task:"145",
        chart:teamMembersTwoChart
    },
    {
        image:"1.jpg",
        clientName:"Roger Lum",
        email:"roger8@gmail.com",
        designation:"Wordpress",
        task:"956",
        chart:teamMembersThreeChart
    },
    {
        image:"3.jpg",
        clientName:"Jeff Johnson",
        email:"jeff761@gmail.com",
        designation:"Nft Web Page",
        task:"324",
        chart:teamMembersFourChart
    }
]

export const designMeetingData = [
      "10:00","11:00","12:00","13:00", "14:00" 
]

export const designMeetingAvatarData =[ 
    {
        title:"Design meeting",
        time:"2 hours",
        avatar:[
            "13.jpg","6.jpg","3.jpg"
        ],
        number:"4",
        color:"primary"
    },
    {
        title:"Weekly scurm Meeting",
        time:"2 hours",
        avatar:[
            "14.jpg","9.jpg","11.jpg"
        ],
        number:"2",
        color:"secondary"
    }
]