import { Link } from 'react-router-dom'
import { Card, CardBody, ButtonGroup, Col, Row, Form, Label, FormGroup, Input} from 'reactstrap'
import MyFarmListDropDown from './MyFarmListDropDown';
import SvgIcon from '../../../../CommonElements/SVG/SvgIcon';

import { Btn } from '../../../../AbstractElements';
import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks';
import React, { useEffect, useState } from 'react';
import { StaffSource, getAllFarmsForForm, getAllStaff, getAllStockSource } from '../../../../ReduxToolkit/Reducers/FarmSlice';
import { CreatePondType, getAllPonds, getPondActivities, getStockingByPond } from '../../../../ReduxToolkit/Reducers/PondsSlice';
import { CustomProjectType } from '../../../../ReduxToolkit/Reducers/ProjectSlice';







const FarmListHead = () => {

    const {alreadyRequested, list} = useAppSelector(state => state.farm.farmList)
    const {pondsAlreadyRequested, pondList, stocking, pondActivities} = useAppSelector(state => state.ponds)
    const { staffList } = useAppSelector(state => state.farm)
    const dispatch = useAppDispatch()   
    const [selectedFarm, setSelectedFarm] = useState<CustomProjectType>({
        creatorId: 0,
        fullsAddress: "",
        id: 0,
        name: "",
        summary: "",
        zipCode: 0
    })
    const [selectedOption, setSelectedOption] = useState<string>()
    const [selectedPond, setSelectedPond] = useState<CreatePondType>({
        name: "",
        projectId: 0,
        position_row: 0,
        position_col: 0,
        depth: 0,
        lenght: 0,
        width: 0,
        id: 0,
        materialType: "",
    })
    const [showPonds, setShowPonds] = useState(false)
    const [selectedStaff, setSelectedStaff] = useState<StaffSource>({
        id: 0,
        firstName: "",
        lastName: "",
        dataCreated: "",
        dateOfBirth: "",
        email: "",
        employmentDate: "",
        farmId: 0,
        homeAddress: "",
        managerId: 0,
        phoneMain: "",
        phoneSecondary: "",
        position: "",
        userId: 0,
    })
    const [selectedStocking, setSelectedStocking] = useState(false)

    useEffect(()=> {
        dispatch(getPondActivities())
    }, [])

    useEffect(() => {
        if(list.length === 0 && !alreadyRequested) {
            dispatch(getAllFarmsForForm())
        }
    }, [list.length])

    const handleFarmSelect = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFarm(list.find(el => el.id === Number(e.target.value)) as CustomProjectType)
    }

    useEffect(() => {
        console.log("Showing info of farm ", selectedFarm)
        dispatch(getAllPonds({projectId: Number(selectedFarm.id)}))
        setShowPonds(false)
        setSelectedPond({
            name: "",
            projectId: 0,
            position_row: 0,
            position_col: 0,
            depth: 0,
            lenght: 0,
            width: 0,
            id: 0,
            materialType: "",
        })
        if(selectedOption === "Staff"){
            setSelectedStaff({
                id: 0,
                firstName: "",
                lastName: "",
                dataCreated: "",
                dateOfBirth: "",
                email: "",
                employmentDate: "",
                farmId: 0,
                homeAddress: "",
                managerId: 0,
                phoneMain: "",
                phoneSecondary: "",
                position: "",
                userId: 0,
            })
         dispatch(getAllStaff({ farmId: selectedFarm.id }))
        }
    }, [selectedFarm.id])

    const handleOptionsSelect = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(e.target.value)
    }
    
    const handleShowPonds = () => {
        setShowPonds(!showPonds)
    }

    const handleSelectedPond = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPond(pondList.find(el => el.id === Number(e.target.value)) as CreatePondType)
    }

    useEffect(() => {
        console.log("showing info of pond ", selectedPond)
        console.log(pondList.find(el => el.id === Number(selectedPond)))
        setSelectedStocking(false)
    }, [selectedPond])

    useEffect(() => {
        if(selectedOption === "Staff") {
            dispatch(getAllStaff({ farmId: selectedFarm.id }))
        }

    }, [selectedOption])

    const handleStaffMember = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSelectedStaff(staffList.find(el => el.id === Number(e.target.value)) as StaffSource)
    }
    
    const handleSelectedStocking = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSelectedStocking(!selectedStocking)
    }

    useEffect(() => {
        if(selectedStocking) {
            dispatch(getStockingByPond({ farmId: selectedFarm.id, pondId: selectedPond?.id || 0}))
        }
    }, [selectedStocking])

    useEffect(() => {
        if(!showPonds) {
            setSelectedPond({
                name: "",
                projectId: 0,
                position_row: 0,
                position_col: 0,
                depth: 0,
                lenght: 0,
                width: 0,
                id: 0,
                materialType: "",
            })
        }
    }, [showPonds])
    return (
        <>
            <Row className="project-cards">
                <Col md="12" className="project-list">
                    <Card>
                        <CardBody className="dropdown-basic m-0">
                            <div className="common-flex">
                                {/* <HeaderForm/> */}
                                <ButtonGroup>
                                    {/* <MyFarmListDropDown/> */}
                                    {/* <Input type="select" className="btn-square digits" defaultValue={"Select Farm "} onChange={handleFarmSelect} >
                                        <option hidden>Select Farm</option>
                                        {
                                            list.length > 0 && list.map((el, index) => {
                                                return(
                                                    <option key={index} value={el.id}>{el.name}</option>
                                                )
                                            })
                                        }
                                    </Input> */}

                                    <Input type="select" className="btn-square digits" defaultValue={"Select option"} disabled={!selectedFarm} onChange={handleOptionsSelect} >
                                        <option hidden>Select Option</option>
                                        <option value="Staff">Staff</option>
                                        <option value="Vendors">Vendors</option>
                                        <option value="Sales">Sales</option>
                                        <option value="Stocks">Stocks</option>
                                    </Input>

                                    <button onClick={handleShowPonds} disabled={!selectedFarm}>Show ponds</button>

                                </ButtonGroup>

                                {/* Create or Update Farm Button */}
                                <Col xs="6" sm="6" md="6" className="text-center">
                                    <Link className="btn btn-primary d-flex align-items-center" target="_blank" to={`${process.env.PUBLIC_URL}/dashboard/UpdateFarm`} >
                                        <SvgIcon className='feather' iconId='plus-square' />
                                        Create / Update
                                    </Link>
                                </Col>

                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
            {
                selectedOption === "Staff" ? (
                    <>
                        <Row classNamme="project-cards">
                            <Col md="12" className="project-list">
                                <Input type="select" className="btn-square digits" value={selectedStaff.id} defaultValue={"Select option"} onChange={handleStaffMember} >
                                        <option value={0} hidden>Select Staff Member</option>
                                        {
                                            staffList.length > 0 && staffList.map((el, index) => {
                                                return(
                                                    <option value={el.id} key={index}>{el.firstName + el.lastName}</option>
                                                )
                                            })
                                        }
                                </Input>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <></>
                )
            }
            {
                selectedStaff && selectedStaff.id > 0 ? (
                    <>
                        <Row classNamme="project-cards">
                            <Col md="12" className="project-list">
                                <Card>
                                    <h1>Name: {selectedStaff.firstName}</h1>
                                    <h1>lastName: {selectedStaff.lastName}</h1>
                                    <span>position: {selectedStaff.position}</span>
                                    <span>email: {selectedStaff.email}</span>
                                    <span>employment date: {selectedStaff.employmentDate}</span>
                                    <span>date of birth: {selectedStaff.dateOfBirth}</span>
                                    <span>phone: {selectedStaff.phoneMain}</span>
                                    <span>phone: {selectedStaff.phoneSecondary}</span>
                                </Card>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <></>
                )
            }
            {
                selectedFarm ? (
                    <Row className="project-cards">
                        <Col md="12" className="project-list">
                            <Card>
                                <h1>Name: {selectedFarm.name}</h1>
                                <p>Summary: {selectedFarm.summary}</p>
                                <span>Address: {selectedFarm.fullsAddress}</span>
                                <span>Zip Code: {selectedFarm.zipCode}</span>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )
            }
            {
                showPonds ? (
                    <>
                    <Row className="project-cards">
                        <Col md="12" clasName="project-list">
                            <Card>
                                <CardBody className="dropdown-basic m-0">
                                    <Input type="select" className="btn-square digits" value={selectedPond.id} onChange={handleSelectedPond} >
                                        <option hidden>Select Pond</option>
                                        {
                                            pondList.length > 0 && pondList.map((el, index) => {
                                                return(
                                                    <option key={index} value={el.id}>{el.name}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="project-cards">
                        <Col md="12" className="project-list">
                            <Card>
                                <h1>Pond name: {selectedPond?.name}</h1>
                                <p>material: {selectedPond?.materialType}</p>
                                <span>depth: {selectedPond?.depth}</span>
                                <span>length: {selectedPond?.lenght}</span>
                                <span>width: {selectedPond?.width}</span>
                                <span>col: {selectedPond?.position_col}</span>
                                <span>row: {selectedPond?.position_row}</span>
                            </Card>
                        </Col>
                    </Row>
                    {
                        selectedPond && selectedPond.id !== 0 ? (
                            <Row className="project-cards">
                                <Col md="12" className="project-list">
                                    <Card>
                                        {
                                            pondActivities.length > 0 && pondActivities.map((el, index)=> {
                                                if(el.name === "STOCKING") return (
                                                    <label key={index}><Input type="radio" className="btn-square digits" name={el.name+el.id} checked={selectedStocking} onChange={handleSelectedStocking} /> {el.name} </label>
                                                )
                                                return(
                                                    <label key={index}><Input type="radio" className="btn-square digits" name={el.name+el.id} />{el.name}</label>
                                                )
                                            })
                                        }
                                    </Card>
                                </Col>
                            </Row>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedStocking ? (
                            <Card>
                                <h1>Stocking</h1>
                                    {
                                        stocking.length > 0 && stocking.map((el, index) => {
                                            return(
                                                <div key={index}>
                                                    <Card>
                                                        <span>added quantity: {el.addedQuantity}</span>
                                                        <span>added weight: {el.addedWeight}</span>
                                                        <span>comments: {el.comments}</span>
                                                    </Card>
                                                </div>
                                            )
                                        })
                                    }
                            </Card>
                        ) : (
                            <></>
                        )
                    }
                    <Row className="project-cards">
                        <Col md="12" className="project-list">
                            <Card>
                            
                            </Card>
                        </Col>
                    </Row>
                    </>
                ) : (
                    <></>
                )
            }

        </>
    );
}

export default FarmListHead