
import { Card, CardBody, Col } from 'reactstrap'
import CardHeaderCommon from '../../../Ui-Kits/CardHeaderCommon';
import FarmModal from './Modals/FarmModal';
import SalesModal from './Modals/SalesModal';
import ActivityNames from './Modals/ActivityNames';
import StockingModal from './Modals/StockingModal';

const ModalTitle ='All Updates Modal';
const ModalDescription = [
    {
        text: "Modals have three optional sizes, available via modifier classes to be placed on a ",
        code: ".modal-dialo",
    },
];

const Farm = () => {
    return (
        <Col lg="12">
            <Card>
                <CardHeaderCommon title={ModalTitle} span={ModalDescription} headClass='pb-0' />
                <CardBody className="badge-spacing">
                    <FarmModal />
                    <SalesModal />
                    <ActivityNames />
                    <StockingModal />
                </CardBody>
            </Card>
        </Col>
    )
}

export default Farm