import { Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { Btn, H5, H6, Image, P } from '../../../../../AbstractElements'
import CommonCardHeader from '../../../../../CommonElements/CommonCardHeader/CommonCardHeader'
import { dynamicImage } from '../../../../../Service'
import { backURL, Bio, Emailaddress, MyProfile, Password, Save, Website } from '../../../../../utils/Constant'
import { useAppDispatch } from '../../../../../ReduxToolkit/Hooks'
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { editUserProfile, UserSliceType } from '../../../../../ReduxToolkit/Reducers/UserSlice'
import { Provider, useSelector } from 'react-redux'
import Store from '../../../../../ReduxToolkit/Store'

const EditMyProfileWrapper = () => {
  return (
    <Provider store={Store}>
      <EditMyProfile />
    </Provider>
  )
}
const EditMyProfile = () => {
  const dispatch = useAppDispatch()
  const user = useSelector((state: { user: UserSliceType }) => state.user);

  type DataSchema = {
    bio: string;
    website: string;
    image?: string;
  }
  const [data, setData] = useState<DataSchema>({
    bio: '',
    website: '',
  })
  
  const handleBio = (e:ChangeEvent<HTMLTextAreaElement>) => {
    setData({
      ...data,
      bio: e.target.value
    })
  }

  const handleWebsite = (e:ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      website: e.target.value
    })
  }

  const submitForm = (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(editUserProfile(data))
  }

  // Handle image upload
  function handleImageUpload(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const picString = reader.result as string;
          setData({
            ...data,
            image: picString
          })
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <Col xl="4">
      <Card>
        <CommonCardHeader title={MyProfile} tagClass='card-title mb-0' />
        <CardBody>
          <Form onSubmit={(e) => submitForm(e)}>
            <Row className="mb-2">
              <div className="profile-title">
                <div className="d-flex">
                  <label htmlFor="upload_image">
                    <Image 
                      className="img-70"
                      id='profilePicture' 
                      alt="edit-user" 
                      src={backURL + user.userProfile.image}
                    />
                  </label>
                  <input onChange={handleImageUpload} type="file" accept='image/*' name="upload_image" id="upload_image" />

                  <div className="flex-grow-1">
                    <H5 className="mb-1">{'Erichy-dev'}</H5>
                    <P>{'DESIGNER'}</P>
                  </div>
                </div>
              </div>
            </Row>
            <FormGroup>
              <H6 className="form-label">{Bio}</H6>
              <textarea rows={5} className="form-control" defaultValue={"On the other hand, we denounce with righteous indignation"}
                onChange={(e) => handleBio(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label>{Emailaddress}</Label>
              <Input placeholder="your-email@domain.com" />
            </FormGroup>
            <FormGroup>
              <Label>{Password}</Label>
              <Input type="password" defaultValue="password" />
            </FormGroup>
            <FormGroup>
              <Label>{Website}</Label>
              <Input onChange={handleWebsite} placeholder="http://Uplor.com" />
            </FormGroup>
            <div className="form-footer">
              <Btn color="primary" block className='w-auto'>{Save}</Btn>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  )
}

export default EditMyProfileWrapper;