import { Container, Row } from 'reactstrap'
import Profile from './Profile/Profile'
import MarkProfile from './MarkProfile/MarkProfile'
import Profile2 from './Profile2/Profile2'
import ProfileDetails from './ProfileDetails/ProfileDetails'
import DetailImage from './DetailImage/DetailImage'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { Users, UsersProfile } from '../../../../utils/Constant'
import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks'
import { useEffect } from 'react'
import { getUserProfile } from '../../../../ReduxToolkit/Reducers/UserSlice'
import { getActivityName } from '../../../../ReduxToolkit/Reducers/FarmSlice'

const UsersProfileContainer = () => {
  const dispatch = useAppDispatch()
  const userProfile = useAppSelector(state => state.user.userProfile)

  useEffect(() => {

    console.log("CARGANDO PERFIL")
    if (userProfile.full_name === '') {
      dispatch(getUserProfile())
      console.log(dispatch(getActivityName()));
      
    }
  }, [])

  return (
    <>
      <Breadcrumbs mainTitle={UsersProfile} parent={Users} />
      <Container fluid>
        <div className="user-profile">
          <Row>
            <Profile
              image={userProfile.image_ProfileSmall}
              name={userProfile.full_name}
              title={userProfile.title}
              birthday={userProfile.birthday}
              phone={userProfile.phone}
              facebook={userProfile.fb}
              instagram={userProfile.instagram}
              twitter={userProfile.twitter}
              email={userProfile.email}
            />
            <MarkProfile />
            <Profile2 />
            <ProfileDetails />
            <DetailImage />
          </Row>
        </div>
      </Container>
    </>
  )
}

export default UsersProfileContainer