
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PondType } from "../../Type/Farm/PondsList";
import axios from "axios";
import { refreshToken } from "./UserSlice";
import { backURL } from "../../utils/Constant";
import { getActivityName } from "./FarmSlice";

const swal = require('sweetalert2');


export type CreatePondType = {
  name?: string
  projectId?: number
  position_row?: number
  position_col?: number
  depth?: number
  lenght?: number
  width?: number
  id: number
  materialType: string
}

type PondDataType = {
  id?: number
  name?: string
}

type RequestPondType = {
  projectId?: number
}

type PondListType = {
  pondsAlreadyRequested: boolean
  pondList: CreatePondType[]
  stocking: StockingT[]
  pondActivities: PondActivitiesT[]
}

type UpdateStockT = {
  farmId: number
  toPondId: number
  type: string
  totalWeight: number
  quantity: number
  fishId: number
  comments: string
}

type StockingT = {
  toPondId: number
  fromPondId: number
  pondId: number
  fishId: number
  addedQuantity: number
  addedWeight: number
  comments: string
}

type PondActivitiesT = {
  name: string
  description: string
  creatorId: number
  id: number
}

export const getAllPonds = createAsyncThunk(
  'project/getAllPonds',
  async (data: RequestPondType, thunkAPI) => {
    // const pondsMockUp:CreatePondType[] = [
    //   {
    //     name: "pond1",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 1,
    //     materialType: "material1",
    //   },
    //   {
    //     name: "pond2",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 2,
    //     materialType: "material2",
    //   },
    //   {
    //     name: "pond1",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 1,
    //     materialType: "material1",
    //   },
    //   {
    //     name: "pond2",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 2,
    //     materialType: "material2",
    //   },
    //   {
    //     name: "pond1",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 1,
    //     materialType: "material1",
    //   },
    //   {
    //     name: "pond2",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 2,
    //     materialType: "material2",
    //   },
    //   {
    //     name: "pond1",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 1,
    //     materialType: "material1",
    //   },
    //   {
    //     name: "pond2",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 2,
    //     materialType: "material2",
    //   },
    //   {
    //     name: "pond1",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 1,
    //     materialType: "material1",
    //   },
    //   {
    //     name: "pond2",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 2,
    //     materialType: "material2",
    //   },
    //   {
    //     name: "pond1",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 1,
    //     materialType: "material1",
    //   },
    //   {
    //     name: "pond2",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 2,
    //     materialType: "material2",
    //   },
    //   {
    //     name: "pond1",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 1,
    //     materialType: "material1",
    //   },
    //   {
    //     name: "pond2",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 2,
    //     materialType: "material2",
    //   },
    //   {
    //     name: "pond1",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 1,
    //     materialType: "material1",
    //   },
    //   {
    //     name: "pond2",
    //     projectId: 1,
    //     position_row: 16,
    //     position_col: 15,
    //     depth: 13,
    //     lenght: 12,
    //     width: 12,
    //     id: 2,
    //     materialType: "material2",
    //   },
    // ]
    // return { data: pondsMockUp}
    try {

      const response = await axios({
        method: 'POST',
        url: `${backURL}/projects/Ponds/`,
        data: {
          'projectId': data.projectId
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
        },
        
      })
      if (response.status === 401) {
        //REFRESH TOKEN
        await refreshToken()
      }
      console.log(response);

      return response.data
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
);


export const createProject = createAsyncThunk(
  'project/createProject',
  async (data: CreatePondType, thunkAPI) => {
    console.log("Project in redux: ", data)
    try {
      const response = await axios({
        method: 'PUT',
        url: `${backURL}/projects/MyProjects/`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
        },
        data: data
      })
      if (response.status === 401) {
        //REFRESH TOKEN
        await refreshToken()
      }
      return response.data
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)

type UpdateStockingProps = {
  data: UpdateStockT
  enableNextTab: () => void
}
export const updateStocking = createAsyncThunk(
  'project/updateStocking',
  async (data:UpdateStockingProps, thunkAPI) => {
    try {
      console.log("UpdateStockingDataInRedux", data.data)
      const response = await axios({
        method: 'PUT',
        url: `${backURL}/projects/Stockings/`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
        },
        data: data.data
      })
      if (response.status === 401) {
        //REFRESH TOKEN
        await refreshToken()
      }
      return response.data
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)
type getStockingByPondT = {
  pondId: number
  farmId: number
}
export const getStockingByPond = createAsyncThunk(
  'project/getStockingByPond',
  async (data:getStockingByPondT, thunkAPI) => {
    try {
      // const stockingMockUp:StockingT[] = [
      //   {
      //     toPondId: 13,
      //     fromPondId: 15,
      //     pondId: 1,
      //     fishId: 1,
      //     addedQuantity: 51,
      //     addedWeight: 11,
      //     comments: "comment1",
      //   },
      //   {
      //     toPondId: 14,
      //     fromPondId: 2,
      //     pondId: 1,
      //     fishId: 2,
      //     addedQuantity: 13,
      //     addedWeight: 31,
      //     comments: "comment2",
      //   }
      // ]
      // const stockingMockUp2:StockingT[] = [
      //   {
      //     toPondId: 13,
      //     fromPondId: 15,
      //     pondId: 1,
      //     fishId: 1,
      //     addedQuantity: 51,
      //     addedWeight: 11,
      //     comments: "commentPond2",
      //   },
      //   {
      //     toPondId: 14,
      //     fromPondId: 2,
      //     pondId: 1,
      //     fishId: 2,
      //     addedQuantity: 13,
      //     addedWeight: 31,
      //     comments: "commentPond2",
      //   }
      // ]
      // return data.pondId === 1 ? stockingMockUp : stockingMockUp2
      const response = await axios({
        method: 'POST',
        url: `${backURL}/projects/Stockings/`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
        },
        data: data
      })
      if (response.status === 401) {
        //REFRESH TOKEN
        await refreshToken()
      }
      return response.data.data
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)

export const getPondActivities = createAsyncThunk(
  'project/getPondActivities',
  async (_, thunkAPI) => {
    try {
      // const activities:PondActivitiesT[] = [
      //   {
      //     name: "STOCKING",
      //     description: "stockdsc",
      //     creatorId: 1,
      //     id: 1
      //   },
      //   {
      //     name: "Feeding",
      //     description: "feedingdesc",
      //     creatorId: 1,
      //     id: 2
      //   }
      // ]
      // return activities
      const response = await axios({
        method: 'GET',
        url: `${backURL}/projects/activityNames`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
        }
      })
      if (response.status === 401) {
        //REFRESH TOKEN
        await refreshToken()
      }
      return response.data.data
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)


const initialState: PondType & PondListType = {
  activeTab: "1",
  pondsAlreadyRequested: false,
  pondList: [],
  stocking: [],
  pondActivities: []
};

const PondsSlice = createSlice({
  name: "PondDataSlice",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    //GETPROJECTS
    builder.addCase(getAllPonds.pending, (state, _) => {
      state.pondsAlreadyRequested = true
      swal.fire({
        title: "Loading ponds",
        icon: "loading",
        toast: true,
        timer: 1000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    })
    builder.addCase(getAllPonds.rejected, (state, action: any) => {
      console.log(action.payload)
      state.pondsAlreadyRequested = true
      swal.fire({
        title: "An error ocurred",
        icon: "error",
        toast: true,
        timer: 1000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    })
    builder.addCase(getAllPonds.fulfilled, (state, action: any) => {
      console.log("FULLFILED PROJECT: ", action.payload)
      state.pondList = action.payload.data ? action.payload.data : []
      state.pondsAlreadyRequested = true
    })

    //Stocking
    builder.addCase(updateStocking.pending, (state, _) => {
      swal.fire({
        title: "Saving Stock",
        icon: "loading",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    })
    builder.addCase(updateStocking.rejected, (state, action:any) => {
      swal.fire({
        title: "An error ocurred",
        icon: "error",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    })
    builder.addCase(updateStocking.fulfilled, (state, action:any) => {
      console.log("UpdateStockinFullfiled")
      swal.fire({
        title: "Stock Updated",
        icon: "success",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
    })
    action.meta.arg.enableNextTab()
    })
    builder.addCase(getStockingByPond.pending, (state, _) => {
      console.log("getting stocking")
    })
    builder.addCase(getStockingByPond.rejected, (state, action:any) => {
      swal.fire({
        title: "Error getting stock",
        icon: "error",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    })
    builder.addCase(getStockingByPond.fulfilled, (state, action:any) => {
      state.stocking = action.payload
      
    })
    //activities
    builder.addCase(getPondActivities.pending, (state, _) => {
      console.log("getting activities names")
    })
    builder.addCase(getPondActivities.rejected, (state, action:any) => {
      swal.fire({
        title: "Error getting activities",
        icon: "error",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    })
    builder.addCase(getPondActivities.fulfilled, (state, action:any) => {
      state.pondActivities = action.payload
      
    })
  }
})

export const {  } = PondsSlice.actions;

export default PondsSlice.reducer;