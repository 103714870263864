
import { TableColumn } from "react-data-table-component";
import { FarmToDoType } from "../../../../ReduxToolkit/Reducers/FarmSlice";
import { ToDoListTable } from "./ToDoList";

export interface ZeroConfigurationTableColumnsType {
    id: number;
    name: string;
    position: string;
    office: string;
    age: number;
    startDate: string;
    salary: string;
    action: string;
  }

export const childRowColumn: TableColumn<ToDoListTable>[] = [
    // {
    //   name: "Pond",
    //   selector: (row) => row.pondName,
    //   sortable: true,
    //   center: false,
    // },
    {
      name: "Pond Name / Task",
      selector: (row) => row.pondName +' / ' + row["activity"],
      sortable: true,
      center: false,
    },
    {
      name: "taskDetails",
      selector: (row) => `${row.taskDetails}`,
      sortable: true,
      center: false,
    },
    {
      name: "Urgency",
      selector: (row) => `${row.urgency}`,
      sortable: true,
      center: false,
    },
    {
      name: "Status",
      selector: (row) => `${row.status}`,
      sortable: true,
      center: false,
    },
    {
      name: "DueDate",
      selector: (row) => `${row.dueDate}`,
      sortable: true,
      center: false,
    },
  ];