import { Table } from "reactstrap";
import { Image } from "../../../../../AbstractElements";
import { dynamicImage } from "../../../../../Service";
import { Invoice } from "../../../../../utils/Constant";

const InvoiceThreeHeader = () => {
  return (
    <td className="p-0">
      <Table responsive borderless style={{ width: "100%", backgroundImage: "url(/assets/images/email-template/invoice-3/bg-0.png)", backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", borderRadius: 10 }} >
        <tbody>
          <tr>
            <td style={{ padding: "30px 0" }}>
              <Image className="for-light" src="/assets/images/favicon/logo.png" alt="logo" />
              <Image className="for-dark" src="/assets/images/favicon/logo.png" alt="logo" />
              <address style={{ opacity: "0.8", width: "36%", marginTop: 10, fontStyle: "normal" }}>
                <span style={{ fontSize: 16, lineHeight: "1.5", fontWeight: 500}} >
                  Thika, Kenya
                </span>
              </address>
            </td>
          </tr>
        </tbody>
      </Table>
    </td>
  );
};

export default InvoiceThreeHeader;
