
import { Card, CardBody, Col } from "reactstrap";
import CardHeaderCommon from "../../../../CommonElements/CommonCardHeader/CardHeaderCommon";
import ReactApexChart from "react-apexcharts";
import { orderOverviewChartData, salesSummaryChartData } from "./ChartData";

import { columnChartData } from "./ApexChartData";



const FarmSummaryChart = () => {
    return (
        <Col sm="4">
            <Card className="sales-summary">
                <CardHeaderCommon title={'Sales Summaries'} firstItem={'Weekly'} secondItem={'Monthly'} thirdItem={'Yearly'} />
                <CardBody>
                    <div className="current-sale-container order-container">
                        {/* <div className="overview-wrapper" id="orderoverview" >
                            <ReactApexChart options={orderOverviewChartData} series={orderOverviewChartData.series} height={268} type="line" />
                        </div> */}
                        <div className="back-bar-container">
                            <ReactApexChart options={salesSummaryChartData} series={salesSummaryChartData.series} height={270} type="bar" />
                        </div>
                        <div>
                            <ReactApexChart options={columnChartData} series={columnChartData.series} type="bar" height={350} />
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>

        
    );
};


export default FarmSummaryChart;

//General Farm:
    // Fish Expenses to Estimated Sales
    // Farm Expense chart { Saff, Fish, Equpment-Maintainace, Structure-Mainainace }
    // Income vs expense 
    // 


//Per Pond:
    // Fish Quantity/Size vs { Feeding-rate, water-change }
    // Fish Expenses to Estimated Sales
    // Mortality tracker 
