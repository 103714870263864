
import { Container, Row } from 'reactstrap'
import BreadcrumbLink from "../../../../CommonElements/Breadcrumbs/BreadcrumbLink"
import { farmURL } from "../../../../utils/Constant"
import Farm from './Farm'


const UpdateFarms = () => {
    return (
        <>
            <BreadcrumbLink mainTitle={"Update Farm"} parent={'Farm'} parentLink={farmURL} />
            <Container fluid>
                <Row>
                    <Farm />
                </Row>
            </Container>
        </>
    )
}

export default UpdateFarms