import { Col, Row } from "reactstrap";
import { userProfileData } from "../../../../../Data/Application/Users/UsersProfile/UsersProfile";
import { H6 } from "../../../../../AbstractElements";

interface ProfileMailProps {
  birthday:string
  email: string
}
const ProfileMail = ({birthday, email}:ProfileMailProps) => {
  return (
    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
      <Row>
          <Col lg="4" md="6" className="order-sm-2 order-xl-2">
            <div className="ttl-info text-start">
              <H6>
                <i className={`fa fa-calendar`} /> BOD
              </H6>
              <span>{birthday}</span>
            </div>
          </Col>
        
          <Col lg="4" md="6" className="order-sm-2 order-xl-2">
            <div className="ttl-info text-start">
              <H6>
                <i className={`fa fa-envelope`} /> Email
              </H6>
              <span>{email}</span>
            </div>
          </Col>
        
      </Row>
    </Col>
  );
};

export default ProfileMail;
