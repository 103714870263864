import {
  All,
  Audios,
  Images,
  Settings,
  Tools,
  Videos,
} from "../../../utils/Constant";

export const navData = [
  { id: 1, icon: "target", tittle: All },
  { id: 2, icon: "image", tittle: Images },
  { id: 3, icon: "video-clapper", tittle: Videos },
  { icon: "map-alt", tittle: Audios },
  { color: "success", tittle: Settings },
  { color: "secondary", tittle: Tools },
];

export const searchTabsData = [
];

export const searchInformation = [
];

export const galleryImagePath = [
  "big-lightgallery/1.jpg",
  "big-lightgallery/2.jpg",
  "big-lightgallery/3.jpg",
  "big-lightgallery/4.jpg",
  "big-lightgallery/5.jpg",
  "big-lightgallery/6.jpg",
  "big-lightgallery/7.jpg",
  "big-lightgallery/8.jpg",
];
