import { H4, Image, LI, P, UL } from '../../../../../AbstractElements'
import { dynamicImage } from '../../../../../Service'
import { SingleBlogHeading } from '../../../../../utils/Constant'

const SingleBlogData = () => {
  const SingleBlogParagraph1 = `From the east coast to the west, each river has its own beauty and character. Each river has its own story. Take a look at some America’s best rivers and some of the rivers we’re working to protect. And learn some facts about your favorite rivers. The Harpeth River and its tributaries are home to rich freshwater biodiversity, including more than 50 species of fish and 30 species of mussels`;
  const SingleBlogParagraph2 = ``;
  return (
    <div className="blog-box blog-details">
      <Image className="img-fluid w-100" src={dynamicImage(`blog/blog-single.jpg`)} alt="blog-main" />
      <div className="blog-details">
        <UL className="blog-social flex-row simple-list">
          <LI>25 July 2024</LI>
          <LI><i className="icofont icofont-user" />Erichy-dev</LI>
          <LI><i className="icofont icofont-thumbs-up" /> 02<span>Hits</span></LI>
          <LI><i className="icofont icofont-ui-chat" /> Comments</LI>
        </UL>
        <H4>{SingleBlogHeading}</H4>
        <div className="single-blog-content-top">
          <P>{SingleBlogParagraph1}</P>
          <P>{SingleBlogParagraph2}</P>
        </div>
      </div>
    </div>
  )
}

export default SingleBlogData