
import { useState } from "react";
import { Form, FormGroup, Input, Label } from 'reactstrap'
import { Btn } from '../../../../../AbstractElements'

import { useAppDispatch, useAppSelector } from "../../../../../ReduxToolkit/Hooks";
import { createActivityName } from "../../../../../ReduxToolkit/Reducers/FarmSlice";

const ActivityNamesForm = () => {

    const dispatch = useAppDispatch()
    
    

    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(formData.name.length > 0 && formData.description.length > 0 ) {
            dispatch(createActivityName({name: formData.name, description: formData.description}))
          }

    }

    return (
        <>
            <Form onSubmit={handleSubmit} className='theme-form'>
                <FormGroup>
                    <Label> Activity Name</Label>
                    <Input
                        type="text"
                        placeholder="Enter Activity Name"
                        required
                        value={formData.name}
                        name="name"
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Activity Description</Label>
                    <Input 
                        type="textarea" 
                        id="exampleFormControlTextarea1" 
                        rows={3} 
                        value={formData.description}
                        name="description"
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    />
                </FormGroup>
                <FormGroup>
                    <Btn color="primary"> Submit </Btn>
                </FormGroup>

            </Form>
        </>

    )
}

export default ActivityNamesForm
