
import { Container, Row } from "reactstrap";
import { Dashboard, Farm } from "../../../utils/Constant";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";

import FarmDetails from "./Departments/FarmDetails";

import FarmListHead from "../../Application/Farm/FarmList/FarmListHead";
import ToDoList from "../../Application/Farm/ToDo/ToDoList";
import FarmSummaryChart from "./Departments/FarmSummaryChart";
import SalesSummary from "../../Widgets/General/SalesSummary/SalesSummary";


import TotalSale from "../Ecommerce/TotalSale/TotalSale";
import ManageOrder from "../Ecommerce/ManageOrder/ManageOrder";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { useEffect, useState } from "react";
import { getAllFarmsForForm, getFarmToDo } from "../../../ReduxToolkit/Reducers/FarmSlice";
import { CustomProjectType } from "../../../ReduxToolkit/Reducers/ProjectSlice";
import { getAllPonds } from "../../../ReduxToolkit/Reducers/PondsSlice";


const ContainerFarm = () => {

    const {alreadyRequested, list:farmList} = useAppSelector(state => state.farm.farmList)
    const { toDoList } = useAppSelector(state => state.farm)
    const dispatch = useAppDispatch()

    const [selectedFarm, setSelectedFarm] = useState<CustomProjectType>({
        creatorId: 0,
        fullsAddress: "",
        id: 0,
        name: "",
        summary: "",
        zipCode: 0
    })


    useEffect(() => {
        if(farmList.length === 0 && !alreadyRequested) {
            dispatch(getAllFarmsForForm())
            if(selectedFarm.id !== 0) {
                dispatch(getFarmToDo({ farmId: selectedFarm.id }))
            }
        }
    }, [farmList.length])

    useEffect(() => {
        if(selectedFarm.id !== 0) {
            dispatch(getAllPonds({ projectId: selectedFarm.id }))
        }
    }, [selectedFarm.id])

    const handleFarmSelect = (id:number) => {
        setSelectedFarm(farmList.find(el => el.id === id) as CustomProjectType)
    }

    return (
        <>
            <Breadcrumbs mainTitle={Farm} parent={Dashboard} />
            <Container fluid className="ecommerce-dashboard">
                <Row>
                    <FarmDetails placeHolder={selectedFarm.name} farmList={farmList} setSelectedFarm={handleFarmSelect} selectedFarm={selectedFarm} />
                    {
                        selectedFarm.id !== 0 ?
                        (<ToDoList farmId={selectedFarm.id} />) : (<></>)
                    }
                    
                    <FarmSummaryChart />
                    <FarmListHead />
                    
                    {/* <ManageOrder /> */}
                    {/*  <TotalSale /> */ }
                </Row>
            </Container>
        </>
    );
};

export default ContainerFarm;