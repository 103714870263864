import { Col } from 'reactstrap'
import { Badges, H5, Image, P, Progressbar } from '../../../../../AbstractElements'
import { dynamicImage } from '../../../../../Service'
import { Done } from '../../../../../utils/Constant'
import ProjectDetails from './ProjectDetails'
import ProjectCustomers from './ProjectCustomers'
import { CommonProjectInterFace } from '../../../../../Type/Application/ProjectList/ProjectList'
import { CustomProjectType } from '../../../../../ReduxToolkit/Reducers/ProjectSlice'

interface ProjectCommonProps {
  project:CustomProjectType
}
const progress =  100
const ProjectCommon = ({ project }: ProjectCommonProps) => {
  return (
    <Col xxl="4" md="6" >
      <div className={`project-box font-dark bg-light-${'Done' === 'Done' ? 'success' : 'danger'}`}>
        <Badges color={`${'Done' === 'Done' ? 'success' : 'danger'}`}>{'Badge'}</Badges>
        <H5 className='f-w-500 mb-2'>{project.name}</H5>
        <div className='d-flex mb-2'>
          <Image className='img-20 me-1 rounded-circle' src={dynamicImage(`user/${'3.jpg'}`)} alt='images' />
          <P className="font-light">{project.fullsAddress}</P>
        </div>
        <P>{project.summary}</P>
        {/* <ProjectDetails item={item}/> */}
        {/* <ProjectCustomers item={item}/> */}
        <div className='project-status align-items-center gap-1 mb-2'>
          <div className='d-flex mb-0'>
            <P className="mb-0">{50}% </P>
            <span>{Done}</span>
          </div>
          <Progressbar animated={progress !== 100 ? true : false} color={progress === 100 ? 'success' : 'danger'} value={progress} style={{ height: '5px' }} />
        </div>
      </div>
    </Col>
  )
}

export default ProjectCommon