import { Image } from "../../../../../AbstractElements";
import { dynamicImage } from "../../../../../Service";
import { Email, Phone, Website } from "../../../../../utils/Constant";

const InvoiceRightLogo = () => {
  return (
    <td className="p-0">
      <Image className="img-fluid for-light" src="/assets/images/favicon/logo.png" alt="logo" style={{height:"50px", marginBottom: 14 }} />
      <Image className="img-fluid for-dark" src="/assets/images/favicon/logo.png" alt="logo" style={{height:"50px", marginBottom: 14 }} />
      <span style={{ display: "block", lineHeight: "1.5", fontSize: 16, fontWeight: 400, opacity: "0.8" }} >
        Thika, Kenya
      </span>
      <span style={{ display: "block", lineHeight: "1.5", fontSize: 16, fontWeight: 400, opacity: "0.8" }} >
        {Phone} : (254) 758287757
      </span>
      <span style={{ display: "block", lineHeight: "1.5", fontSize: 16, fontWeight: 400, opacity: "0.8" }} >
        {Email} : support@nyumbakumi.net
      </span>
      <span style={{ display: "block", lineHeight: "1.5", fontSize: 16, fontWeight: 400, opacity: "0.8"}}>
        {Website}: https://dashboard.nyumbakumi.net
      </span>
    </td>
  );
};

export default InvoiceRightLogo;
