import { Row } from 'reactstrap'
import ProfileMail from './ProfileMail'
import ProfileName from './ProfileName'
import ProfileContact from './ProfileContact'
import SocialMedia from './SocialMedia'
import ProfileFollower from './ProfileFollower'

interface ProfileDetailProps {
  name:string
  title:string
  phone:number
  birthday:string
  facebook:string
  instagram:string
  twitter:string
  email:string
}
const ProfileDetail = ({email, name, title, phone, birthday, instagram, facebook, twitter}:ProfileDetailProps) => {
  return (
    <div className="info">
      <Row>
        <ProfileMail birthday={birthday}  email={email}/>
        <ProfileName name={name} title={title}/>
        <ProfileContact phone={phone}/>
      </Row>
      <hr />
      <SocialMedia instagram={instagram} twitter={twitter} facebook={facebook} />
      <ProfileFollower />
    </div>
  )
}

export default ProfileDetail