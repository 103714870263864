import { useEffect, useState } from "react";
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavLink, NavItem, Input, Label, TabContent, TabPane, Row } from "reactstrap";

import { dynamicImage } from "../../../../Service";
import { Href } from "../../../../utils/Constant";
import { iconTabData, iconSubTabData } from "../../../../Data/Ui-Kits/BootstrapTabs/BootstrapTabs";

import { SVG, Image, H6, LI, P, UL } from "../../../../AbstractElements";
import CardHeaderCommon from "../../../Ui-Kits/CardHeaderCommon";
import { TabContentProp } from "../../../../Type/Ui-Kits/UiKitsTypes";
import { CustomProjectType } from "../../../../ReduxToolkit/Reducers/ProjectSlice";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { CreatePondType, getAllPonds } from "../../../../ReduxToolkit/Reducers/PondsSlice";
import { PondType } from "../../../../Type/Farm/PondsList";
import { StaffSource, getAllStaff } from "../../../../ReduxToolkit/Reducers/FarmSlice";
enum FarmOptions {
    Ponds,
    Security,
    Staff,
    Fishes,
    Vendors
}
interface IconsRadioProps {
    setSelectedOption: (option:FarmOptions) => void
}
const IconsRadio = ({ setSelectedOption }:IconsRadioProps) => {
    return (
        <Col xxl="12" sm="12" className="order-xxl-0 order-sm-1">
            <div className="card-wrapper solid-border rounded-3">
                <div className="form-check radio ps-0">
                    <UL className="radio-wrapper simple-list flex-row">
                        <LI onClick={() => setSelectedOption(FarmOptions.Ponds)}>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-sliders"></i><span>{FarmOptions[FarmOptions.Ponds]}</span>
                            </Label>
                        </LI>
                        <LI onClick={() => setSelectedOption(FarmOptions.Security)}>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-child"></i>
                                <span>{FarmOptions[FarmOptions.Security]}</span>
                            </Label>
                        </LI>
                        <LI onClick={() => setSelectedOption(FarmOptions.Staff)}>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-users"></i>
                                <span>{FarmOptions[FarmOptions.Staff]}</span>
                            </Label>
                        </LI>
                        <LI onClick={() => setSelectedOption(FarmOptions.Fishes)}>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-bullseye"></i>
                                <span>{FarmOptions[FarmOptions.Fishes]}</span>
                            </Label>
                        </LI>
                        <LI onClick={() => setSelectedOption(FarmOptions.Vendors )}>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-barcode"></i>
                                <span>{FarmOptions[FarmOptions.Vendors]}</span>
                            </Label>
                        </LI>

                    </UL>

                </div>
            </div>
        </Col>
    )
}


interface SubIconsRadioProps {
    ponds?: CreatePondType[]
    staff?: StaffSource[]
    option: FarmOptions
}
const SubIconsRadio = ({ option, ponds, staff }:SubIconsRadioProps) => {
    return (
        <Col xxl="12" sm="12" className="order-xxl-0 order-sm-1">
            <div className="card-wrapper solid-border rounded-3">
                <div className="form-check radio ps-0">
                    <UL className="radio-wrapper simple-list flex-row bg-red-500" style={{ height: "12rem", overflowY: "scroll" }}>
                        {
                            option === FarmOptions.Ponds && ponds &&ponds.length > 0 && ponds.map((el, index) => {
                                return(
                                    <LI key={index}>
                                        <Input id="radio-icon" type="radio" name="radio2" />
                                        <Label check for='radio-icon'>
                                            <i className="fa fa-sliders"></i><span>{el.name}</span>
                                        </Label>
                                    </LI>
                                )
                            })
                        }
                        {
                            option === FarmOptions.Staff && staff && staff.length > 0 && staff.map((el, index) => {
                                return(
                                    <LI key={index}>
                                        <Input id="radio-icon" type="radio" name="radio2" />
                                        <Label check for='radio-icon'>
                                            <i className="fa fa-sliders"></i><span>{el.firstName[0] + ". " + el.lastName[0] + "."}</span>
                                        </Label>
                                    </LI>
                                )
                            })
                        }
                        {/* <LI>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-sliders"></i><span>All</span>
                            </Label>
                        </LI>
                        <LI>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-child"></i>
                                <span>A1</span>
                            </Label>
                        </LI>
                        <LI>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-users"></i>
                                <span>A2</span>
                            </Label>
                        </LI>
                        <LI>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-bullseye"></i>
                                <span>A3</span>
                            </Label>
                        </LI>
                        <LI>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-barcode"></i>
                                <span>A4</span>
                            </Label>
                        </LI>
                        <LI>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-barcode"></i>
                                <span>A5</span>
                            </Label>
                        </LI>
                        <LI>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-bullseye"></i>
                                <span>B1</span>
                            </Label>
                        </LI>
                        <LI>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-barcode"></i>
                                <span>B2</span>
                            </Label>
                        </LI>
                        <LI>
                            <Input id="radio-icon" type="radio" name="radio2" />
                            <Label check for='radio-icon'>
                                <i className="fa fa-barcode"></i>
                                <span>B3</span>
                            </Label>
                        </LI> */}

                    </UL>

                </div>
            </div>
        </Col>
    )
}

interface IconTabContentProps {
    selectedFarm: CustomProjectType
}

const IconTabContent: React.FC<TabContentProp & IconTabContentProps> = ({ basicTab, selectedFarm }) => {

    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const { pondList } = useAppSelector(state => state.ponds)
    const { staffList } = useAppSelector(state => state.farm)
    const dispatch = useAppDispatch()
    const [selectedOption, setSelectedOption] = useState<FarmOptions>()

    const handleOption = (option:FarmOptions) => {
        setSelectedOption(option)
    }

    useEffect(() => {
        if(selectedFarm.id !== 0) {
            dispatch(getAllPonds({ projectId: selectedFarm.id }))
            dispatch(getAllStaff({ farmId: selectedFarm.id }))
        }
    }, [selectedFarm.id])

    return (
        <>
            <TabContent activeTab={basicTab}>
                <TabPane tabId="1">
                    <IconsRadio setSelectedOption={handleOption}/>
                    <P className="pt-3"> .</P>
                    {
                        selectedOption === FarmOptions.Ponds ? (
                            <>
                                <CardHeaderCommon title={FarmOptions[FarmOptions.Ponds]} span={iconSubTabData} headClass="pb-0" />
                                <SubIconsRadio option={selectedOption} ponds={pondList}/>
                            </>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedOption === FarmOptions.Staff ? (
                            <>
                                <CardHeaderCommon title={FarmOptions[FarmOptions.Staff]} span={iconSubTabData} headClass="pb-0" />
                                <SubIconsRadio option={selectedOption} staff={staffList}/>
                            </>
                        ) : (
                            <></>
                        )
                    }

                </TabPane>
                <TabPane tabId="2">
                    <div className="pt-3 mb-0">
                        <div className="flex-space flex-wrap align-items-center">
                            <Image className="tab-img" src={dynamicImage(`avatar/2.jpg`)} alt="profile" />
                            <UL className="d-flex flex-column gap-1 simple-list">
                                <LI><strong>Name:  </strong> {selectedFarm.name}</LI>
                                <LI><strong>Summary:</strong> {selectedFarm.summary}</LI>
                                <LI><strong>Address: </strong> {selectedFarm.fullsAddress}</LI>
                                <LI><strong>Zip Code: </strong> {selectedFarm.zipCode}</LI>
                            </UL>
                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="3">
                    <div>
                        <P className="pt-3 mb-2">Us Technology offers web &amp; mobile development solutions for all industry verticals.Include a short form using fields that'll help your business understand who's contacting them. </P>
                        <Label check for="exampleFormControlone">{'EmailAddress'}</Label>
                        <Input id="exampleFormControlone" type="email" placeholder="youremail@gmail.com" />
                    </div>
                </TabPane>
            </TabContent>
        </>
    )

}

interface FarmDetailsProps {
    farmList: CustomProjectType[]
    setSelectedFarm: (id:number) => void
    placeHolder: string
    selectedFarm: CustomProjectType
}

const FarmDetails = ({ farmList, setSelectedFarm, selectedFarm, placeHolder }:FarmDetailsProps) => {

    const dispatch = useAppDispatch()
    const {pondsAlreadyRequested, pondList} = useAppSelector(state => state.ponds)
    const [basicTab, setBasicTab] = useState<string>("2");
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    

    return (



        <Col lg="4">
            <Card>
                <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                    <DropdownToggle className="view-btn btn bg-light d-block w-100 position-relative text-center" caret>{placeHolder || 'Select Farm ...'}</DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        {
                                farmList.length > 0 && farmList.map((el, index) => {
                                    return(
                                        <DropdownItem key={index} onClick={() => setSelectedFarm(el.id)}>{el.name}</DropdownItem>
                                    )
                                })
                        }
                        {/* <DropdownItem>{'Project'} </DropdownItem>
                        <DropdownItem>{'Ecommerce'}</DropdownItem>
                        <DropdownItem>{'Crypto'} </DropdownItem> */}
                    </DropdownMenu>
                </Dropdown>
                <CardHeaderCommon title={selectedFarm.name} span={iconTabData} headClass="pb-0" />
                <CardBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                href={Href}
                                className={`font-info ${basicTab === "1" ? "active" : ""}`}
                                onClick={() => setBasicTab("1")}
                            >
                                <SVG className="svg-color stroke-info" iconId="Home" /> {'Farm Options'}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href={Href}
                                className={`font-info ${basicTab === "2" ? "active" : ""}`}
                                onClick={() => setBasicTab("2")}
                            >
                                <SVG className="svg-color stroke-info" iconId="Contacts" /> {'Contacts'}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href={Href}
                                className={`font-info ${basicTab === "3" ? "active" : ""}`}
                                onClick={() => setBasicTab("3")}
                            >
                                <SVG className="svg-color stroke-info" iconId="Profile" /> {'Images'}
                            </NavLink>
                        </NavItem>

                    </Nav>
                    <IconTabContent basicTab={basicTab} selectedFarm={selectedFarm}/>

                </CardBody>

            </Card>
        </Col>
    );
};

export default FarmDetails;




