
import React, { useEffect, useMemo, useState } from 'react'
import DataTable from "react-data-table-component";


import { childRowColumn } from './ApiDataTables';

import { Card, CardBody, Col, Table, Label, Input } from "reactstrap";
import CommonCardHeader from '../../../../CommonElements/CommonCardHeader/CommonCardHeader';
import ChildRows from "../../../Tables/DataTables/ApiDataTable/ChildRows/ChildRows";
import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks';
import { FarmToDoType, TodoActivityType, getActivities, getFarmToDo } from '../../../../ReduxToolkit/Reducers/FarmSlice';

const childRowData = [
    {
        text: "Showing all pending task",
    },
];


interface CustomExpandableComponentProp {
    data: {
      id: number;
      name: string;
      position: string;
      office: string;
      age: number;
      startDate: string;
      salary: string;
      action: string;
    };
  }
interface CustomActivitiesExpandable {
    data: ToDoListTable
}
const CustomExpandableComponent : React.FC<CustomActivitiesExpandable> = ({ data }) => {
    return (
      <Table cellPadding={5} cellSpacing={0} border={0} style={{ paddingLeft: 50 }}>
        <tbody>
          {/* <tr>
            <td>:</td>
            <td>{data.id}</td>
          </tr> */}
          <tr>
            <td>{"Description"}:</td>
            <td>{data.activityDescription}</td>
          </tr>
          <tr>
            <td>{"Details"}:</td>
            <td>{data.taskDetails}</td>
          </tr>
          {/* <tr>
            <td>{"Comments"}:</td>
            <td>{"ApiExtraInfo"}</td>
          </tr> */}
        </tbody>
      </Table>
    );
  };


  interface TableDataI {
    data: ToDoListTable[]
  }
const ChildRow = ({ data }:TableDataI) => {

    interface ZeroConfigurationTableColumnsType {
        id: number;
        name: string;
        position: string;
        office: string;
        age: number;
        startDate: string;
        salary: string;
        action: string;
    }

    const zeroConfigurationData = [
        {
            id: 1,
            name: "Airi Satou",
            position: "Accountant",
            office: "Tokyo",
            age: 33,
            startDate: "2008/11/28",
            salary: "$162,700",
            action: "t-1",
        },
        {
            id: 2,
            name: "Ashton Cox",
            position: "Junior Technical Author",
            office: "San Francisco",
            age: 66,
            startDate: "2009/01/12",
            salary: "$86,000",
            action: "t-2",
        },
        {
            id: 3,
            name: "Brielle WilLIamson",
            position: "Integration SpeciaLIst",
            office: "New York",
            age: 61,
            startDate: "2012/12/02",
            salary: "$372,000",
            action: "t-3",
        },
        {
            id: 4,
            name: "Cedric Kelly",
            position: "Senior Javascript Developer",
            office: "Edinburgh",
            age: 22,
            startDate: "2012/03/29",
            salary: "$433,060",
            action: "t-4",
        },
        {
            id: 5,
            name: "Charde Marshall",
            position: "Regional Director",
            office: "San Francisco",
            age: 36,
            startDate: "2008/10/16",
            salary: "$470,600",
            action: "t-5",
        },
        {
            id: 6,
            name: "Colleen Hurst",
            position: "Javascript Developer",
            office: "San Francisco",
            age: 39,
            startDate: "2009/09/15",
            salary: "$205,500",
            action: "t-6",
        },
        {
            id: 7,
            name: "Donna Snider",
            position: "Customer Support",
            office: "New York",
            age: 27,
            startDate: "2011/01/25",
            salary: "$112,000",
            action: "t-7",
        },
        {
            id: 8,
            name: "Garrett Winters",
            position: "Accountant",
            office: "Tokyo",
            age: 63,
            startDate: "2011/07/25",
            salary: "$170,750",
            action: "t-8",
        },
        {
            id: 9,
            name: "Herrod Chandler",
            position: "Sales Assistant",
            office: "San Francisco",
            age: 59,
            startDate: "2012/08/06",
            salary: "$137,500",
            action: "t-9",
        },
        {
            id: 10,
            name: "Jena Gaines",
            position: "Office Manager",
            office: "London",
            age: 30,
            startDate: "2008/12/19",
            salary: "$90,560",
            action: "t-10",
        },
        {
            id: 11,
            name: "Quinn Flynn",
            position: "Support Lead",
            office: "Edinburgh",
            age: 22,
            startDate: "2013/03/03",
            salary: "$342,000",
            action: "t-11",
        },
        {
            id: 12,
            name: "Rhona Davidson",
            position: "Integration SpeciaLIst",
            office: "Tokyo",
            age: 55,
            startDate: "2010/10/14",
            salary: "$327,900",
            action: "t-12",
        },
        {
            id: 13,
            name: "Sonya Frost",
            position: "Software Engineer",
            office: "Edinburgh",
            age: 23,
            startDate: "2008/12/13",
            salary: "$103,600",
            action: "t-13",
        },
        {
            id: 14,
            name: "Tiger Nixon",
            position: "System Architect",
            office: "Edinburgh",
            age: 61,
            startDate: "2011/04/25",
            salary: "$320,800",
            action: "t-14",
        },
    ];



    const [filterText, setFilterText] = useState("");
    const filteredItems = zeroConfigurationData.filter((item) => item.office && item.office.toLowerCase().includes(filterText.toLowerCase()));

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <div id="API-chield-row_filter" className="dataTables_filter d-flex align-items-center">
              <Label className="me-2">{'Search'}:</Label>
              <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)} type="search" value={filterText} />
            </div>
          );
    }, [filterText]);

    return (
        <Col sm="12">
            <Card>
                <CommonCardHeader title={'ToDo List'} span={childRowData} />
                <CardBody>
                    <div className="table-responsive">
                        <div id="API-chield-row">
                            <DataTable
                                // paginationTotalRows={4}
                                paginationPerPage={4}
                                paginationRowsPerPageOptions={[4, 7, 10]}                                
                                data={data}
                                columns={childRowColumn} pagination expandableRows
                                expandableRowsComponent={CustomExpandableComponent}
                                striped highlightOnHover className="display"
                            />
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )

}

interface TodoList {
    farmId: number
}

export type ToDoListTable =  {
    pondName: string
    activity: string
    taskDetails: string
    dueDate: string
    urgency: number
    status: number
    activityDescription: string
}
const ToDoList = ({farmId}:TodoList) => {

    const { pondList } = useAppSelector(state => state.ponds)
    const { activities, toDoList} = useAppSelector(state => state.farm)
    const dispatch = useAppDispatch()
    const [toDoListActivity, setToDoListActivity] = useState<ToDoListTable[]>([])

    useEffect(() => {
        if(activities.length === 0) {
            dispatch(getActivities())
        }
        if(farmId !== 0) {
            dispatch(getFarmToDo({ farmId: farmId }))
        }
    }, [farmId])

    useEffect(() => {
        if(toDoList && toDoList.length > 0 && activities.length > 0) {
            // console.log("activities: ", activities)
            // console.log("todolist: ", toDoList)
            // console.log(toDoList.map(listEl => {
            //     const activity:ToDoListTable = {
            //                 taskDetails: listEl.taskDetails,
            //                 dueDate: listEl.dueDate,
            //                 urgency: listEl.urgency,
            //                 status: listEl.status,
            //                 activity: activities.find(el => {
            //                     el.id === listEl.taskId
            //                 })?.name || ''
            //             }
            //             console.log("TTTTTT: ", activity)
            // }))
            setToDoListActivity(toDoList.map(listEl => {
                const activity:ToDoListTable = {
                    taskDetails: listEl.taskDetails,
                    dueDate: listEl.dueDate,
                    urgency: listEl.urgency,
                    status: listEl.status,
                    activity: activities.find(el => el.id === listEl.taskId)?.name || '',
                    activityDescription: activities.find(el => el.id === listEl.taskId)?.description || '',
                    pondName: pondList.find(el => el.id === listEl.pondId)?.name || ''
                }
                return activity
            }))
        }
    }, [toDoList.length])
    
    const columns = ["task", "dueDate", "urgency", "status", "taskDetails"]
    return (
        <Col lg="4">
            {
                toDoListActivity.length > 0 &&
                <ChildRow data={toDoListActivity} />
            }
        </Col>

    )
}


export default ToDoList;