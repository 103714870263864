import { Link } from 'react-router-dom'
import { LI, UL } from '../../../../../AbstractElements'
import { tourProfileData } from '../../../../../Data/Application/Users/UsersProfile/UsersProfile'

interface SocialMediaProps {
  facebook:string
  instagram:string
  twitter:string
}
const SocialMedia = ({facebook, instagram, twitter}:SocialMediaProps) => {
  return (
    <div className="social-media" >
      <UL className="list-inline simple-list flex-row">
        {/* {tourProfileData.map((item, index) => (
          <LI className="list-inline-item" key={index}>
            <Link to={item.link} target="_blank">
              <i className={`fa fa-${item.icon}`} />
            </Link>
          </LI>
        ))} */}
        <LI className="list-inline-item" >
          <Link to={`https://www.facebook.com/${facebook}`} target="_blank">
            <i className={`fa fa-facebook`} />
          </Link>
        </LI>
        <LI className="list-inline-item" >
          <Link to={`https://www.twitter.com/${twitter}`} target="_blank">
            <i className={`fa fa-twitter`} />
          </Link>
        </LI>
        <LI className="list-inline-item" >
          <Link to={`https://www.instagram.com/${instagram}`} target="_blank">
            <i className={`fa fa-instagram`} />
          </Link>
        </LI>
      </UL>
    </div>
  )
}

export default SocialMedia