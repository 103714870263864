import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken } from "./UserSlice";
import { ToDoSliceProp } from "../../Type/Application/Todo/Todo";
import { todoListData } from "../../Data/Application/Todo/Todo";
import { toast } from "react-toastify";
import axios from "axios";
const swal = require('sweetalert2');


const backURL = "https://dashboard-api.nyumbakumi.net"

export type CustomPondsToDoType = {   

createDate:string 
dueDate: string
id: number
pomdName: string
pondId: number
projectId: number
requestorId: number
status: number
taskDetails: string
taskId: number
taskName: string
urgency: number
  
}

type ProjectListType = {
  toDoListAlreadyRequested: boolean
  toDoList: CustomPondsToDoType[]
}




export const getmyPondsToDo = createAsyncThunk(
  'farm/GETUserToDoList',
  async (_, thunkAPI) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${backURL}/projects/myPondsToDo/`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
        }
      })
      if (response.status === 401) {
        //REFRESH TOKEN
        await refreshToken()
      }
      return response.data
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }

);


export const createPondTodDoList = createAsyncThunk(
  'project/createProject',
  async(data:CustomPondsToDoType, thunkAPI) => {
    console.log("myPondsToDo in redux: ", data)
    try {
      const response = await axios({
        method: 'PUT',
        url: `${backURL}/projects/myPondsToDo/`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
        },
        data: data
      })
      if (response.status === 401) {
        //REFRESH TOKEN
        await refreshToken()
      }
      return response.data
    } catch(err:any) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)

const initialState: ToDoSliceProp & ProjectListType = {
  showSideBar: false,
  todoList: [...todoListData],
  task: "",
  visible: false,
  allTaskCompleted: false,
  
  toDoListAlreadyRequested: false,
  toDoList: []
  
};

const ToDoSlice = createSlice({
  name: "ToDoSlice",
  initialState,
  reducers: {
    setVisible: (state, action) => {
      state.visible = action.payload;
    },
    setShowSideBar: (state) => {
      state.showSideBar = !state.showSideBar;
    },
    setTodoList: (state, action) => {
      state.todoList = action.payload;
    },
    setTask: (state, action) => {
      state.task = action.payload;
    },
    setAllTaskCompleted: (state, action) => {
      state.allTaskCompleted = action.payload;
      if (state.allTaskCompleted) {
        const temp = state.todoList.map((data) => (data.status === "pending" ? { ...data, status: "completed", badge: "Done" } : data));
        state.todoList = temp;
        toast.success("All tasks marked as complete.");
      } else {
        const temp = state.todoList?.map((data) => (data.status === "completed" ? { ...data, status: "pending", badge: "Pending" } : data));
        state.todoList = temp;
        toast.error("All tasks marked as In-complete.");
      }
    },
    addNewTodo: (state, action) => {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const currentDate = new Date();
      const temp = {
        id: state.todoList.length + 1,
        title: action.payload,
        status: "pending",
        badge: "Pending",
        badgeClass: "light-danger",
        timeLimit: currentDate.getDate() + " " + months[currentDate.getMonth()],
      };
      state.todoList = [temp, ...state.todoList];
    },
    removeItems: (state, action) => {
      state.todoList = state.todoList?.filter((data) => data.id !== action.payload.id);
      toast.success(`'${action.payload.title} ' has been deleted`);
    },
    updateTodo: (state, action) => {
      const { id, status, badgeClass } = action.payload;
      const temp = state.todoList.map((item) => {
        if (item.id === id) {
          if (status === "pending") {
            return { ...item, status, badgeClass: badgeClass, badge: "Pending" };
          } else if (status === "completed") {
            return { ...item, status, badgeClass: badgeClass, badge: "Done" };
          }
        }
        return item;
      });
      state.todoList = temp;
    },
  }, extraReducers: (builder) => {

    //GET TO DO LIST
    builder.addCase(getmyPondsToDo.pending, (state, _) => {
      console.log("Getting Ponds ToDo List");
      swal.fire({
        title: "Loading ToDO List",
        icon: "loading",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    })
    builder.addCase(getmyPondsToDo.rejected, (state, action: any) => {
      console.log(action.payload)
      swal.fire({
        title: "An error ocurred",
        icon: "error",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    })
    builder.addCase(getmyPondsToDo.fulfilled, (state, action:any) => {
      console.log("FULLFILED GET TODO LIST: ", action.payload)
      state.toDoList= action.payload
      state.toDoListAlreadyRequested = true
    })
    //CreateProject
    builder.addCase(createPondTodDoList.pending, (state, _) => { 
      swal.fire({
        title: "Creating project",
        icon: "loading",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    })
    builder.addCase(createPondTodDoList.rejected, (state, action:any) => {
      console.log(action.payload)
      swal.fire({
        title: "An error ocurred",
        icon: "error",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    })
    builder.addCase(createPondTodDoList.fulfilled, (state, action:any) => {
      swal.fire({
        title: "Project created",
        icon: "success",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
      window.location.reload()
    })

  }
});

export const { setShowSideBar, setTodoList, setTask, addNewTodo, removeItems, updateTodo, setVisible, setAllTaskCompleted } = ToDoSlice.actions;

export default ToDoSlice.reducer;
