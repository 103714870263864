
import CommonModal from "../../../../Ui-Kits/Modal/Common/CommonModal";
import { useState } from 'react'

import { Btn } from "../../../../../AbstractElements";
import SvgIcon from "../../../../../CommonElements/SVG/SvgIcon";
import ActivityNamesForm from "../Forms/ActivityNames";


const modalName = 'Activity Names';
const formName = ' Create New Activity Names'



const ActivityNames = () => {
    const [ActivityNamesModalScreen, setActivityNamesModalScreen] = useState(false);
    const ActivityNamesModalScreenToggle = () => setActivityNamesModalScreen(!ActivityNamesModalScreen);

    return (
        <>
            <Btn color="secondary" onClick={ActivityNamesModalScreenToggle}><SvgIcon className='feather' iconId='plus-circle' /> {modalName}</Btn>
            <CommonModal size="l" isOpen={ActivityNamesModalScreen} toggle={ActivityNamesModalScreenToggle} sizeTitle={modalName}>
                <ActivityNamesForm />
            </CommonModal>
        </>
    )
}


export default ActivityNames
