import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { projectData } from "../../Data/Application/ProjectList/ProjectList";
import { ProjectType } from "../../Type/Application/ProjectList/ProjectList";
import axios from "axios";
import { refreshToken } from "./UserSlice";
const swal = require('sweetalert2')


const backURL = "https://dashboard-api.nyumbakumi.net"

export type CustomProjectType = {   
  id: number
  name: string
  summary: string
  fullsAddress: string
  zipCode: number
  creatorId: number
}
type CreateProjctType = {
  name?: string
  contactName?: string
  contactPhone?: number
  contactEmail?: string
  instagram?: string
  facebook?: string
  status?: number
  fullsAddress?: string
  State?: string
  Country?: string
  zipCode?: number
  comments?: string
  creatorId?: number
}
type ProjectListType = {
  alreadyRequested: boolean
  projectList: CustomProjectType[]
}

export const getAllProjects = createAsyncThunk(
  'project/getAllProjects',
  async(_, thunkAPI) => {
    try {

      const response = await axios({
        method: 'GET',
        url: `${backURL}/projects/myProjects/`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
        }
      })
      if (response.status === 401) {
        //REFRESH TOKEN
        await refreshToken()
      }
    console.log(response.data);

      return response.data.data
    } catch(err:any) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)

export const createProject = createAsyncThunk(
  'project/createProject',
  async(data:CreateProjctType, thunkAPI) => {
    console.log("Project in redux: ", data)
    try {
      const response = await axios({
        method: 'PUT',
        url: `${backURL}/projects/MyProjects/`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
        },
        data: data
      })
      if (response.status === 401) {
        //REFRESH TOKEN
        await refreshToken()
      }
      return response.data
    } catch(err:any) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)

const initialState: ProjectType & ProjectListType = {
  activeTab: "1",
  createdFormData: projectData,
  alreadyRequested: false,
  projectList: []
};

const ProjectSlice = createSlice({
  name: "SideBarSlice",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setCreatedData: (state, action) => {
      state.createdFormData = action.payload;
    },
  },
  extraReducers: (builder) => {
    //GETPROJECTS
      builder.addCase(getAllProjects.pending, (state, _) => {
        state.alreadyRequested = true
        swal.fire({
          title: "Loading projects",
          icon: "loading",
          toast: true,
          timer: 6000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
    })
    builder.addCase(getAllProjects.rejected, (state, action:any) => {
      console.log(action.payload)
      state.alreadyRequested = true
      swal.fire({
        title: "An error ocurred",
        icon: "error",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    })
    builder.addCase(getAllProjects.fulfilled, (state, action:any) => {
      console.log("FULLFILED PROJECT: ", action.payload)
      state.projectList= action.payload
      state.alreadyRequested = true
    })
    //CreateProject
    builder.addCase(createProject.pending, (state, _) => { 
      swal.fire({
        title: "Creating project",
        icon: "loading",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    })
    builder.addCase(createProject.rejected, (state, action:any) => {
      console.log(action.payload)
      swal.fire({
        title: "An error ocurred",
        icon: "error",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    })
    builder.addCase(createProject.fulfilled, (state, action:any) => {
      swal.fire({
        title: "Project created",
        icon: "success",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
      window.location.reload()
    })
  }
});
export const { setActiveTab, setCreatedData } = ProjectSlice.actions;

export default ProjectSlice.reducer;