import { useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import DatePicker from "react-datepicker";

export const ToDoRadio = () => {

    const [startDate, setStartDate] = useState(new Date());
    const handleChange = (date: Date) => setStartDate(date);
    const { validation } = useAppSelector((state) => state.tasks);

    return (
        <Col md="12" className="mt-0">
            <FormGroup>
                <div className="d-flex date-details">

                    <div className="d-inline-block">
                        <Label>Due Date: </Label>
                        <DatePicker className={`form-control flatpickr-input ${validation ? "is-valid" : ""}`} selected={startDate} onChange={handleChange} />
                    </div>
                </div>
            </FormGroup>
        </Col>
    )
}
