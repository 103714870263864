import {createContext, useState, useEffect, SetStateAction, Dispatch} from "react";
//import jwt_decode from "jwt-decode";
import { JwtPayload, jwtDecode } from "jwt-decode";
import {useNavigate} from "react-router-dom";
const swal = require('sweetalert2')


export interface IAuth {
    user: JwtPayload | null
    setUser: Dispatch<SetStateAction<JwtPayload | null>>
    authTokens: any
    setAuthTokens: Dispatch<any>
    registerUser: (email:string, username:string, password:string, password2:string) => Promise<void>
    loginUser: (email:string, password:string) => Promise<void>
    logoutUser: () => void
}
const AuthContext = createContext<IAuth>(null!);

export default AuthContext

export const AuthProvider = ({ children }:Readonly<{children:React.ReactNode}>) => {
    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens") ?? "")
            : null
    );
    

    const [user, setUser] = useState(() => 
        localStorage.getItem("authTokens")
            ? jwtDecode(localStorage.getItem("authTokens") ?? "")
            : null
    );


    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const loginUser = async (email:string, password:string) => {
        try {
            const response = await fetch("https://dashboard-api.nyumbakumi.net/users/token/", {
                method: "POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body: JSON.stringify({
                    email, password
                })
            })
            const data = await response.json()
            console.log(data);
    
            if(response.status === 200){
                console.log("Logged In");
                setAuthTokens(data)
                setUser(jwtDecode(data.access))
                localStorage.setItem("authTokens", JSON.stringify(data))
                //***below needs to redirect to original page before login redirect
                navigate("/")
                swal.fire({
                    title: "Login Successful",
                    icon: "success",
                    toast: true,
                    timer: 6000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                })
    
            } else {    
                //***Need to be notified if email dosent exits and highlight register button
                //***Need to be notified if email exisit but password is wrong and highlight remeber password button
                console.log(response.status);
                console.log("there was a server issue");
                swal.fire({
                    title: "Username or passowrd does not exists",
                    icon: "error",
                    toast: true,
                    timer: 6000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                })
            }
        } catch(e) {
            swal.fire({
                title: "An Error Occured",
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }

    const registerUser = async (email:string, username:string, password:string, password2:string) => {
        try {
            const response = await fetch("https://dashboard-api.nyumbakumi.net/users/register/", {
                method: "POST",
                headers: {
                    "Content-Type":"application/json"
                },
                body: JSON.stringify({
                    email, username, password, password2
                })
            })
            if(response.status === 201){
                navigate("/login")
                swal.fire({
                    title: "Registration Successful, Login Now",
                    icon: "success",
                    toast: true,
                    timer: 6000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                })
            } else {
                console.log(response.status);
                console.log("there was a server issue");
                swal.fire({
                    title: "An Error Occured " + response.status,
                    icon: "error",
                    toast: true,
                    timer: 6000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                })
            }
        } catch(e:any) {
            swal.fire({
                title: "An Error Occured",
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }

    const logoutUser = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem("authTokens")
        navigate("/login")
        swal.fire({
            title: "You have been logged out...",
            icon: "success",
            toast: true,
            timer: 6000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
        })
    }

    const contextData = {
        user, 
        setUser,
        authTokens,
        setAuthTokens,
        registerUser,
        loginUser,
        logoutUser,
    }

    useEffect(() => {
        if (authTokens) {
            setUser(jwtDecode(authTokens.access))
        }
        setLoading(false)
    }, [authTokens, loading])

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    )

}