
import { ChangeEvent, Fragment, FormEvent, useState, useEffect } from 'react'
import { SubmitHandler, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import SvgIcon from "../../../../CommonElements/SVG/SvgIcon";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { setAllTasks, setValidation } from "../../../../ReduxToolkit/Reducers/TasksSlice";
import { FormDataTypes } from "../../../../Type/Application/Tasks/Tasks";
import { AddNewTask, Cancel, NewTask, Save, SubTask, TaskTitle } from "../../../../utils/Constant";
import { ToDoRadio } from './ToDoRadio';
import { getAllProjects } from '../../../../ReduxToolkit/Reducers/ProjectSlice';
import { createPondTodDoList, getmyPondsToDo } from "../../../../ReduxToolkit/Reducers/ToDoSlice";
import { getAllPonds } from '../../../../ReduxToolkit/Reducers/PondsSlice';
import { Console, log } from 'console';

const NewTaskClass = () => {


  const { allTasks, validation } = useAppSelector((state) => state.tasks);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [addModal, setAddModal] = useState(false);
  const addToggle = () => {
    setAddModal(!addModal);
    reset({
      description: "",
      title: "",
      sunTitle: ""
    });
  };

  const [taskData, settaskData] = useState({
    famrId: '',
    password: ''
  });

  const { toDoList, toDoListAlreadyRequested } = useAppSelector((state) => state.todo)
  useEffect(() => {
    if (toDoList.length === 0 && alreadyRequested === false) {
      dispatch(getmyPondsToDo())
    }
  }, [])


  const { projectList, alreadyRequested } = useAppSelector((state) => state.project)
  useEffect(() => {
    if (projectList.length === 0 && alreadyRequested === false) {
      dispatch(getAllProjects())
    }
  }, [])




  const {pondList, pondsAlreadyRequested} = useAppSelector((state)=>state.ponds)
  const populatePonds = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    dispatch(getAllPonds({projectId: parseInt(e.target.value)}))
    settaskData({...taskData, famrId: e.target.value})
  }

  const submitTask =(e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log(taskData.famrId);
    
    //if(formData.email.length > 0) {

    //}
  }




  const [data, setData] = useState({
    createDate: '',
    dueDate: '',
    id: -1,
    pomdName: '',
    pondId: -1,
    projectId: -1,
    requestorId: -1,
    status: -1,
    taskDetails: '',
    taskId: -1,
    taskName: '',
    urgency: -1
  })

  const handleBio = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setData({
      ...data,
      taskDetails: e.target.value
    })
  }

  const handleTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      taskName: e.target.value
    })
  }

  const submitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(createPondTodDoList(data))
  }

  const addTask: SubmitHandler<FormDataTypes> = (data) => {
    if (data.description !== "" && data.title !== "") {
      const taskTemp = {
        id: allTasks.length + 1,
        title: data.title,
        collection: "General",
        description: data.description,
      };
      dispatch(setAllTasks([...allTasks, taskTemp]));
      dispatch(setValidation(false));
      setAddModal(false);
      reset({
        description: "",
        title: "",
        sunTitle: ""
      });
    }
  };
  return (
    <Fragment>
      <Btn block color="light-primary" className="btn-mail w-100" onClick={addToggle}>
        <SvgIcon iconId="check-circle" className="feather me-2" /> {NewTask}
      </Btn>
      <Modal isOpen={addModal} toggle={addToggle} size="lg">
        <div className="modal-header">
          <h5 className="modal-title">{AddNewTask}</h5>
          <Btn close color="transprant" onClick={addToggle} />
        </div>
        <ModalBody>
          {/* <Form className="form-bookmark needs-validation" onSubmit={handleSubmit(addTask)}> */}
          <Form
            className="form-bookmark needs-validation"
            onSubmit={(e) => submitForm(e)}
          >
            <Row>
              
              
              <Col md="4" className="mt-0">
                <FormGroup>
                <Label>{'Farms'}</Label>
                  <Input 
                  type="select" 
                  className="form-control js-example-basic-single" 
                  onChange={populatePonds}>
                    <option value="My Task">Select Farm</option>
                    {projectList.length > 0 && projectList.map((data, i) => (
                      <option value={data.id}>{data.name}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4" className="mt-0">
                <FormGroup>
                <Label>{'Pond'}</Label>
                  <select className={`js-example-disabled-results form-control form-select js-example-basic-single`}>
                    <option value="General">Select Pond</option>
                    {pondList.length > 0 && pondList.map((data, i) => (
                      <option value={data.projectId}>{data.name}</option>
                    ))}

                  </select>
                </FormGroup>
              </Col>
              <Col md="4" className="mt-0">
                <FormGroup>
                <Label>Activity</Label>
                  <select className={`js-example-disabled-results form-control form-select js-example-basic-single`} name="activity">
                    <option value="General">Select Activity</option>
                    <option value="Security">Change Water</option>
                    <option value="General">Sorting </option>
                    <option value="Security">Top Water</option>
                    <option value="General">Feeding</option>
                    <option value="Security">Stocking</option>
                    <option value="General">Sales</option>
                    <option value="Security">Treatment</option>

                  </select>
                </FormGroup>
              </Col>
              <Col md="12" className="mt-0">
                <FormGroup>
                  <Label check>{'Task Title'}</Label>
                  {/* <input type="text" className={`form-control ${validation && `${errors.title ? "is-invalid" : "is-valid"}`}`} {...register("title", { required: true })} /> */}
                  <input
                    type="text"
                    className={`form-control ${validation && `${errors.title ? "is-invalid" : "is-valid"}`}`}
                    {...register("title", { required: true })}
                    onChange={handleTitle} placeholder="To DO List Title"
                  />
                </FormGroup>
              </Col>
              
              <Col md="12" className="my-0">
                <FormGroup>
                <Label>{'Task Description'}</Label>
                  <textarea className={`form-control ${validation && `${errors.description ? "is-invalid" : "is-valid"}`}`} {...register("description", { required: true })} />
                </FormGroup>
              </Col>
              <ToDoRadio />
              <Col md="12">
                <FormGroup className="mt-0">
                  <Label>{'Coments'}</Label>
                  <input type="text" className={`form-control ${validation && `${errors.sunTitle ? "is-invalid" : "is-valid"}`}`} {...register("sunTitle", { required: true })} />
                </FormGroup>
              </Col>
            </Row>
            <Btn 
              color="secondary" 
              className="me-1" 
              type="submit" 
              //onClick={() => dispatch(setValidation(true))}
              onClick={submitTask}
              >
              {Save}
            </Btn>
            <Btn color="primary" onClick={addToggle}>
              {Cancel}
            </Btn>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default NewTaskClass;
