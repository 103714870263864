import { Card, Col } from 'reactstrap'
import { Image } from '../../../../../AbstractElements'
import { dynamicImage } from '../../../../../Service'
import ProfileDetail from './ProfileDetail'

interface ProfileProps {
  image:string
  name:string
  title:string
  phone:number
  birthday:string
  facebook:string
  twitter:string
  instagram:string
  email:string
}
const Profile = ({image, name, title, phone, birthday, instagram, twitter, facebook, email}:ProfileProps) => {
  return (
    <Col sm="12">
      <Card className="hovercard text-center">
        <div className="cardheader" />
        <div className="user-image">
          <div className="avatar">
            <Image src={image} alt="profile"/>
          </div>
          <div className="icon-wrapper">
            <i className="icofont icofont-pencil-alt-5"/>
          </div>
        </div>
        <ProfileDetail email={email} name={name} title={title} birthday={birthday} phone={phone} instagram={instagram} twitter={twitter} facebook={facebook}/>
      </Card>
    </Col>
  )
}

export default Profile