
import { Nav, NavItem, NavLink } from 'reactstrap'
import { BusinessFormCommonProps } from "../../../../../../Type/Forms/FormsLayout/FormsWizardTwo"
import { H6 } from '../../../../../../AbstractElements'
import { stockingNavData } from './StockingData'

interface CustomStockingNavProps {
    enableTabs: number[]
}
const StockingNav: React.FC<BusinessFormCommonProps & CustomStockingNavProps> = ({ callbackActive, activeTab, enableTabs}) => {
    const handleTab = (id: number | undefined) => {
        if (id !== undefined) {
          callbackActive(id);
        }
      };
    return (
        <Nav className="nav-pills horizontal-options" id="horizontal-wizard-tab">
            {stockingNavData.map((data, index) => (
                <NavItem key={index} >
                    <NavLink
                        disabled={enableTabs.filter(el => el === index).length > 0 ? false : true}
                        className={`${activeTab === index + 1 ? "active" : ""}`}
                        onClick={() => handleTab(data.activeTab)}
                        href="#javascript">
                        <div className="horizontal-wizard">
                            <div className="stroke-icon-wizard">
                                <i className={`fa ${data.iconName}`} />
                            </div>
                            <div className="horizontal-wizard-content">
                                <H6>{data.tittle}</H6>
                            </div>
                        </div>
                    </NavLink>
                </NavItem>
            ))}
        </Nav>
    )
}

export default StockingNav