import { useState, useEffect } from "react";
import { H5, Image, LI } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import UserProfileIcons from "./UserProfileIcons";

import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks'
import { getUserProfile, UserSliceType } from '../../../../ReduxToolkit/Reducers/UserSlice'
import { Provider, useSelector } from "react-redux";
import Store from "../../../../ReduxToolkit/Store";
import { backURL } from "../../../../utils/Constant";

const UserProfileWrapper = () => {
  return (
    <Provider store={Store}>
      <UserProfile />
    </Provider>
  )
}

const UserProfile = () => {
  const user = useSelector((state: { user: UserSliceType }) => state.user);

  const [show,setShow] =  useState(false)
  const dispatch = useAppDispatch()
  const userProfile = useAppSelector(state => state.user.userProfile) 


  useEffect(() => {
    if(userProfile.email === '') {
      dispatch(getUserProfile())
    }
  }, [])



  return (
    <LI className="profile-dropdown custom-dropdown">
      <div className="d-flex align-items-center" onClick={()=>setShow(!show)}>
        <Image 
          src={backURL + user.userProfile.image}
          alt="avatar" 
          className="img-70"
        />
        <div className="flex-grow-1">
          <H5>{userProfile.full_name}</H5>
          <span>{userProfile.title}</span>
        </div>
      </div>
      <div className={`custom-menu overflow-hidden ${show? "show" : ""}`}>
        <UserProfileIcons />
      </div>
    </LI>
  );
};

export default UserProfileWrapper;
