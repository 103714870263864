
import CommonModal from "../../../../Ui-Kits/Modal/Common/CommonModal";
import { Fragment, useState } from 'react'

import { Btn } from "../../../../../AbstractElements";
import SvgIcon from "../../../../../CommonElements/SVG/SvgIcon";
import CustomHorizontalWizard from "../../../../Forms/FormsLayout/FormWizardTwo/CustomHorizontalWizard/CustomHorizontalWizard";

const FarmModal = () => {


    const [farmUpdateModalScreen, setfarmUpdateModalScreen] = useState(false);
    const farmUpdateModalScreenToggle = () => setfarmUpdateModalScreen(!farmUpdateModalScreen);

    return (
        <>
            <Btn color="secondary" onClick={farmUpdateModalScreenToggle}><SvgIcon className='feather' iconId='plus-circle' /> {'Update Farm'}</Btn>
            <CommonModal size="xl" isOpen={farmUpdateModalScreen} toggle={farmUpdateModalScreenToggle} sizeTitle="Update Farm">
                <CustomHorizontalWizard differentId heading={'Create New Farm Form'} horizontalWizardClass="vertical-options" firstXl={3} secondXl={9} />
            </CommonModal>
        </>
    )
}


export default FarmModal
