import { Card, CardBody, Col, Row } from 'reactstrap'
import { useCallback, useState } from 'react';

import FarmSlice from "../../../../../ReduxToolkit/Reducers/FarmSlice"
import CommonCardHeader from '../../../../../CommonElements/CommonCardHeader/CommonCardHeader'

import { CustomWizardFormPropsType } from "../../../../../Type/Farm/FarmType"
import StockingNav from './StockingComponents/StockingNav'
import StockingFormTabBody from './StockingComponents/StockingFormTabBody';

const StockingForm: React.FC<CustomWizardFormPropsType> = ({ heading, horizontalWizardClass, xs, firstXl, secondXl, differentId }) => {

    const [activeTab, setActiveTab] = useState<number | undefined>(1);
    const [enableTabs, setEnableTabs] = useState([0])
    const callback = useCallback((tab: number | undefined) => {
        setActiveTab(tab);
    }, []);

    const addTab = (tabIndex:number) => {
        setEnableTabs([...enableTabs, tabIndex])
    }
    return (
        <Col sm="12">

            <Card>
                <CommonCardHeader title={heading} />
                <CardBody>
                    <div className={`horizontal-wizard-wrapper ${horizontalWizardClass}`}>
                        <Row className="g-3">
                            <Col md={firstXl} xs={xs} className="main-horizontal-header">
                                <StockingNav callbackActive={callback} activeTab={activeTab} enableTabs={enableTabs} />
                            </Col>
                            <Col xl={secondXl} xs={xs}>
                                <StockingFormTabBody activeTab={activeTab} callbackActive={callback} differentId={differentId} setEnableTabs={addTab}/>
                            </Col>

                        </Row>

                    </div>
                </CardBody>

            </Card>
        </Col>
    )
}

export default StockingForm