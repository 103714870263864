
import { useAppDispatch, useAppSelector } from '../../../../../../ReduxToolkit/Hooks';
import React, { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import { TabContent, TabPane, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { BusinessFormCommonProps } from '../../../../../../Type/Forms/FormsLayout/FormsWizardTwo';
import { getAllProjects } from '../../../../../../ReduxToolkit/Reducers/ProjectSlice';

import { getAllFarmsForForm, getAllStockSource, getAllStaff, cretateStocking} from '../../../../../../ReduxToolkit/Reducers/FarmSlice';
import { getAllPonds } from '../../../../../../ReduxToolkit/Reducers/PondsSlice';

import { Btn, H6 } from '../../../../../../AbstractElements';
import { Dropzone, FileMosaic, ExtFile } from "@dropzone-ui/react";


interface CustomPersonalInfoForm {
    enableNextTab: (tabId: number) => void
}
const PersonalInfoForm: React.FC<BusinessFormCommonProps & CustomPersonalInfoForm> = ({ callbackActive, enableNextTab }) => {

    const dispatch = useAppDispatch()
    const { personalDetailsForm } = useAppSelector((state) => state.formWizardTwo)
    const { firstName, lastName, contact, email, state, zip } = personalDetailsForm;

    const [formData, setFormData] = useState({
        farmId: -1,
        toPondId: -1,
        fromPondId: -1,
        type: "",
        totalWeight: 0,
        quantity: 0,
        weight: 0,
        lead: -1,
        assitedBy: -1,
        fishStage: "",
        fishId: -1,
        comments: ""
    })
    const [pondsDisable, setPondsDisable] = useState(true)
    const [stockSourceDisable, setStockSourceDisable] = useState(true)
    const [staffDisable, setstaffDisable] = useState(true)
    const [enableForm, setEnableForm] = useState(true)

    const farmList = useAppSelector((state) => state.farm.farmList)
    const ponds = useAppSelector((state) => state.ponds)
    const stockSourceList = useAppSelector((state) => state.farm.stockSourceList)
    const staffList = useAppSelector((state) => state.farm.staffList)
    

    useEffect(() => {
        if (farmList.list.length === 0 && farmList.alreadyRequested === false) {
            dispatch(getAllFarmsForForm())
        }
    }, [])

    useEffect(() => {
        if (formData.farmId !== -1) {
            dispatch(getAllPonds({ projectId: formData.farmId }))
            dispatch(getAllStockSource({ farmId: formData.farmId }))
            dispatch(getAllStaff({ farmId: formData.farmId }))
        }
    }, [formData.farmId])
    

    useEffect(() => {
        if (formData.farmId === -1 || ponds.pondList.length === 0) setPondsDisable(true)
        else setPondsDisable(false)
    }, [formData.farmId, ponds.pondList.length])

    useEffect(() => {
        if (formData.farmId === -1 || staffList.length === 0) setstaffDisable(true)
        else setstaffDisable(false)
    }, [formData.farmId, staffList.length]) 

    useEffect(() => {
        if (formData.farmId === -1 || stockSourceList.length === 0) setStockSourceDisable(true)
        else setStockSourceDisable(false)
    }, [formData.farmId, stockSourceList.length])

    useEffect(() => {
        if (formData.farmId !== -1 &&
            formData.fromPondId !== -1 &&
            formData.toPondId !== -1 &&
            formData.fishId !== -1 &&
            formData.quantity > 0 &&
            formData.lead !== -1 &&
            formData.assitedBy !== -1 && 
            formData.weight > 0
        ) {
            setEnableForm(false)
        }
      
    }, [formData.farmId, formData.fromPondId, formData.toPondId, formData.fishId, formData.quantity, formData.weight, formData.lead, formData.assitedBy, formData.fishStage, formData.comments])

    const enableSecondTab = () =>{
      enableNextTab(1)
    }
    console.log(farmList);

    //Image Uploads
    const [files, setFiles] = useState<ExtFile[]>([]);
    const updateFiles = (incomingFiles: ExtFile[]) => {
        setFiles(incomingFiles);
    };
    const removeFile = (id: string | number | undefined) => {
        setFiles(files.filter((x: ExtFile) => x.id !== id));
    };


    // const { projectList }= useAppSelector((state) => state.project.projectList);
    //const projectData =  dispatch(getAllProjects());
    //console.log(projectData);

    const handleSelectedFarm = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            farmId: Number(e.target.value)
        })
    }

    const handleSelectedPond = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: Number(e.target.value)
        })
    }

    const handleStockSource = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            fishId: Number(e.target.value)
        })
    }

    const handleQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            quantity: Number(e.target.value)
        })
    }
    const handleWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            weight: Number(e.target.value)
        })
    }
    const handleComments = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            comments: e.target.value
        })
    }
    const handleLead = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            lead: Number(e.target.value)
        })
    }

    const handleassitedBy= (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            assitedBy: Number(e.target.value)
        })
    }
    const handleFIshTypes = (e:React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            type: e.target.value
        })
    }

    const handleSelectedFStage= (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            fishStage: e.target.value
        })
    }
    
    const submitForm = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        // dispatch(updateStocking({ data: formData, enableNextTab: enableSecondTab}))
    }
    const seeLogs = () => {
        console.log(formData)
        console.log(stockSourceDisable)
        console.log("StockSource: ", stockSourceList)
        console.log(pondsDisable)
        console.log("pondList: ", ponds)
        console.log("staffList: ", staffList)
    }

    const handleNextButton = () => {

        if (formData.farmId !== -1 &&
            formData.fromPondId !== -1 &&
            formData.toPondId !== -1 &&
            formData.fishId !== -1 &&
            formData.quantity > 0 &&
            formData.lead !== -1 &&
            formData.assitedBy !== -1 && 
            formData.weight > 0
        )   processStock(); 
        else toast.error("Please fill all *field then press continue button");
    };

    const processStock = () => {
        dispatch(cretateStocking({ 
            farmId: formData.farmId,
            toPondId : formData.toPondId,
            fromPondId: formData.fromPondId, 
            fishStage: formData.fishStage,
            fishId: formData.fishId,
            addedQuantity: formData.quantity,
            addedWeight: formData.weight,
            comments: formData.comments,
        }))
        callbackActive(2); 
    }

    return (
        <Form onSubmit={submitForm} className="needs-validation" noValidate>
            <Row className='g-3'>
                <Col xl="5" sm="4">
                    <Label onClick={() => console.log(formData)} check>Farm<span className="font-danger">*</span></Label>
                    <Input type="select" name="farm" onChange={handleSelectedFarm}>
                        <option value="" hidden>Choose Farm...</option>
                        {
                            farmList.list.length > 0 && farmList.list.map((el, index) => {
                                return (
                                    <option key={index} value={el.id}>{el.name}</option>
                                )
                            })
                        }
                    </Input>
                </Col>
                <Col xl="4" sm="3">
                    <Label onClick={seeLogs} check>Stock Source<span className="font-danger">*</span></Label>
                    <Input type="select" name="fishId" onChange={handleStockSource} disabled={stockSourceDisable}>
                        <option value="" hidden>Choose Stock Source...</option>
                        {
                            stockSourceList.length > 0 && stockSourceList.map((el, index) => {
                                return (
                                    <option key={index} value={el.id}>{el.name}</option>
                                )
                            })
                        }
                    </Input>
                </Col>
                <Col xl="3" sm="3">
                    <Label check>Fish Life Stage<span className="font-danger">*</span></Label>
                    <Input type="select"  name="state" onChange={handleSelectedFStage}>
                        <option value="">Choose Life Stage...</option>
                        <option value="Eggs">Eggs...</option>
                        <option value="Fries">Fries</option>
                        <option value="Fingerlings">Fingerlings...</option>
                        <option value="Post_Fingerlings">Post_Fingerlings</option>
                        <option value="Juvenie">Juvenie</option>
                        <option value="Jumbo">Jumbo</option>
                        <option value="drying_size">drying_size</option>
                        <option value="table_size">table_size</option>
                        <option value="smoking_size">smoking_size</option>
                        <option value="1kg">1kg</option>
                    </Input>
                </Col>
                <Col xl="6" sm="4">
                    <Label check>Pond(Fish Removed From)<span className="font-danger">*</span></Label>
                    <Input type="select" name="fromPondId" onChange={handleSelectedPond} disabled={pondsDisable}>
                        <option value="" hidden>From Vendor</option>
                        {
                            ponds.pondList.length > 0 && ponds.pondList.filter(el => el.id !== formData.toPondId).map((el, index) => {
                                return (
                                    <option key={index} value={el.id}>{el.name}</option>
                                )
                            })
                        }
                    </Input>
                </Col>
                <Col xl="6" sm="4">
                    <Label check>Pond(Fish Added too)<span className="font-danger">*</span></Label>
                    <Input type="select" name="toPondId" onChange={handleSelectedPond} disabled={pondsDisable}>
                        <option value="" hidden>Choose Farm...</option>
                        {
                            ponds.pondList.length > 0 && ponds.pondList.filter(el => el.id !== formData.fromPondId).map((el, index) => {
                                return (
                                    <option key={index} value={el.id}>{el.name}</option>
                                )
                            })
                        }
                    </Input>
                </Col>

                <Col xl="3" sm="6">
                    <Label check>Quantity Added <span className="font-danger">*</span></Label>
                    <Input type="number" placeholder="Pick Farm" name="quantity" onChange={handleQuantity} disabled={stockSourceDisable} />
                </Col>
                <Col xl="3" sm="6">
                    <Label check>Weight Added (kg)<span className="font-danger">*</span></Label>
                    <Input type="number" placeholder="Pick Farm" name="weight" onChange={handleWeight} disabled={stockSourceDisable} />
                </Col>
                <Col xl="12" xs="12" className="mt-2">
                    <Label className="f-w-500" check>{' Comments '}</Label>
                    <Input type="textarea" rows={3} name="describeIssue" onChange={handleComments} disabled={stockSourceDisable} />
                </Col>
                <Col xl="4" sm="4">
                    <Label check>Stocking Performed By<span className="font-danger">*</span></Label>
                    <Input type="select"  name="lead" disabled={staffDisable} onChange={handleLead}>
                        <option value="">.....</option>
                        {
                            staffList.length > 0 && staffList.filter(el => el.id !== formData.assitedBy).map((el, index) => {
                                return (
                                    <option key={index} value={el.id}>{el.firstName}</option>
                                )
                            })
                        }
                    </Input>
                </Col>
                <Col xl="4" sm="4">
                    <Label check>Stocking Assited By<span className="font-danger">*</span></Label>
                    <Input type="select"  name="assitedBy" disabled={staffDisable} onChange={handleassitedBy}>
                        <option value="">......</option>
                        {
                            staffList.length > 0 && staffList.filter(el => el.id !== formData.lead).map((el, index) => {
                                return (
                                    <option key={index} value={el.id}>{el.firstName}</option>
                                )
                            })
                        }
                    </Input>
                </Col>
                <Col xs="12">
                    <FormGroup check>
                        <Input id="invalid-check-wizard" type="checkbox" />
                        <Label className="mb-0 d-block" for="invalid-check-wizard" check>{'Agree To Terms And Conditions'}</Label>
                    </FormGroup>
                </Col>
                <Col xs="12">
                    <FormGroup>
                        <Label check>{'Upload Stocking Images Here (10max)'}</Label>
                        <Dropzone onChange={updateFiles} value={files} maxFiles={10} header={false} footer={false} minHeight="80px" label="Drag'n drop files here or click to Browse">
                            {files.map((file: ExtFile) => (
                                <FileMosaic key={file.id} {...file} onDelete={removeFile} info={true} />
                            ))}
                            {files.length === 0 && (
                                <div className="dz-message needsclick p-5">
                                    <i className="icon-cloud-up font-primary"></i>
                                    <H6>{'Drop Files Here'}</H6>
                                    <span className="note needsclick">
                                        OR
                                    </span>
                                    <H6>{'Click To Upload'}</H6>
                                    
                                </div>
                            )}
                        </Dropzone>
                    </FormGroup>
                </Col>
                
                <Col xs="12" className="text-end mt-3">
                    <Btn color="primary" onClick={handleNextButton}>{'Continue'}</Btn>
                </Col>
                <Input type="submit" value={"Send data!"} disabled={enableForm} />
            </Row>
        </Form>
    )
}

interface CustomStockingFormTabBodyProps {
    setEnableTabs: (tabId: number) => void
}

const StockingFormTabBody: React.FC<BusinessFormCommonProps & CustomStockingFormTabBodyProps> = ({ activeTab, callbackActive, differentId, setEnableTabs }) => {

    return (<>
        <TabContent className=" dark-field " activeTab={activeTab}>
            <TabPane tabId={1}>
                <PersonalInfoForm callbackActive={callbackActive} enableNextTab={setEnableTabs} />
            </TabPane>
            <TabPane tabId={2}>
                TabPane 2
                <pre>thisis the pre of the pre</pre>
                {/* <BankInfoForm callbackActive={callbackActive} /> */}
            </TabPane>
            <TabPane tabId={3}>
                TabPane 3
                {/* <InquiresForm callbackActive={callbackActive} differentId={differentId} /> */}
            </TabPane>
            <TabPane tabId={4}>
                TabPane 4
                {/* <FinishForm /> */}
            </TabPane>
        </TabContent>
    </>)
}

export default StockingFormTabBody


